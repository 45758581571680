import { Component, OnInit, Input } from '@angular/core';
import { OrderService } from '../../../services/order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DeliverablesService } from '../../../services/deliverables.service';
import { OrderInformation } from '../../order/models/order-information.model';
import { SwalAlertService } from '../../../../app/shared/swal-alert/swal-alert.service';

@Component({
    selector: 'app-order-history-details',
    templateUrl: './order-history-details.component.html',
    styleUrls: ['./order-history-details.component.css']
})
export class OrderHistoryDetailsComponent implements OnInit {
    id: string;
    orderInfo: OrderInformation;
    isCartFull: boolean = false;
    @Input() isSupport: boolean;
    order: any;
    token: any;
    setting = { element: { dynamicDownload: null as HTMLElement } }


    constructor(
        private router: Router,
        private orderService: OrderService,
        private route: ActivatedRoute,
        private deliverablesService: DeliverablesService,
        private swalService: SwalAlertService
    ) {
        this.route.params.subscribe(_ => { this.id = _.id; });
    }

    ngOnInit() {
        this.getClientOrderDetails();
    }


    public setToken(token: any) { this.token = token }

    getClientOrderDetails() {
        this.orderService.getOrderDetailsById(this.id).subscribe((order) => {
            this.orderInfo = <OrderInformation>{}
            this.setOrderDetails(order);
            this.order = order;
        })
    }

    public setOrderDetails(order: any) {
        this.orderInfo.id = order.id;
        this.orderInfo._id = order._id;
        this.orderInfo.submissionDate = order.submissionDate;
        this.orderInfo.payment = order.payment;
        this.orderInfo.invoice = order.idCommande;
        this.orderInfo.proForma = order.idProForma;
        this.orderInfo.state = order.state;
    }


    async editOrder(response: any) {
        if (response) {
            let isReadyToAbort: any;

            if (this.isCartFull) {
                isReadyToAbort = await this.swalService
                    .getSwalCheckboxNotification(`You are about to edit your order !`, '', 'warning', 'Delete current cart items', 'Check the box to confirm');

            }
            else {
                isReadyToAbort = await this.swalService.getSwalForConfirm(`You are about to edit your order !`, '');
            }

            if (isReadyToAbort.value) {

                this.orderService.editOrder(this.orderInfo.id)
                    .subscribe(result => {
                        if (result) {
                            this.swalService.getSwalForNotification(`Order ${this.orderInfo.id} aborted`, ` <b> Order ${this.orderInfo.id} aborted`)
                        }
                        error => {
                            this.swalService.getSwalForNotification('Abortion Failed !', error.message, 'error')
                        }
                    })
                this.router.navigate(['/order/history']);
            }
        }
    }

    downloadLinks(response: any) {

        if (response) {

            let fileName = this.orderInfo.id + "_Manifest";
            let downloadablelinks = [];

            this.deliverablesService.getLinks(this.orderInfo.id).subscribe(productslinks => {

                productslinks.forEach(links => {
                    links.forEach(link => { downloadablelinks.push(link) })
                });
                const downloadeLinksString = downloadablelinks.join('\n');
                const textFileAsBlob = new Blob([downloadeLinksString], { type: 'text/plain', endings: 'native' });

                const downloadLink = document.createElement("a");
                downloadLink.download = fileName;
                if (window.webkitURL != null) {
                    if (!this.setting.element.dynamicDownload) {
                        this.setting.element.dynamicDownload = document.createElement('a');  // without actually adding it to the DOM.
                        downloadLink.href = window.webkitURL.createObjectURL(textFileAsBlob);
                        document.body.appendChild(downloadLink);
                    }
                    downloadLink.click();
                }
            })
        }
    }
}




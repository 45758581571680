import { Routes } from "@angular/router";
import { GuardGuard } from "../../guard/guard.guard";
import { ClientOrderDetailsComponent } from "./client-order-details/client-order-details.component";
import { ClientOrderComponent } from "./client-order/client-order.component";
import { SupportComponent } from "./support/support.component";

export const supportRoutes: Routes = [
    {
        path: 'support', component: SupportComponent,
        children: [
            { path: 'clientorder', component: ClientOrderComponent, canActivate: [GuardGuard] },
            { path: 'clientorder/:id', component: ClientOrderDetailsComponent, canActivate: [GuardGuard] }
        ]
    }

]

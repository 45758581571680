import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { Subject } from 'rxjs';

import { OrderService } from '../../../services/order.service';

import { DataTableDirective } from 'angular-datatables';

import { environment } from '../../../../environments/environment';
import { BreadCrumbService } from '../../../services/bread-crumb.service';
import { DatePipe } from '@angular/common';
import { SupportService } from '../../../services/support.service';
import { CurrencyService } from '../../../services/currency.service';

class DataTablesResponse {
  listorders: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

class Orders {
  _id: string;
  id: string;
  name: string;
}

@Component({
  selector: 'app-client-order',
  templateUrl: './client-order.component.html',
  styleUrls: ['./client-order.component.css'],
  providers: [DatePipe]
})
export class ClientOrderComponent implements OnInit {
  @ViewChild("SDate") submitdateinput: ElementRef;
  @ViewChild("PType") purchasetypeinput: any;
  @ViewChild("State") StateInput: any;
  // @ViewChild("PType") purchasetypeinput: ElementRef;

  dateSubmission: Date;
  states: Array<any>;
  search: string;
  message: string;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  listorders: Orders[] = [];
  orders: Array<Object>;
  symbols: any[];

  constructor(
    private http: HttpClient,
    private router: Router,
    private httpc: HttpClient,
    private orderService: OrderService,
    private breadCrumbService: BreadCrumbService,
    private datePipe: DatePipe,
    private supportService: SupportService,
    private currencyService: CurrencyService
  ) { }

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  private countriesForm: NgForm;

  ngOnInit() {
    this.message = '';
    this.dtOptions = {};
    // this.state = '';
    this.getOrders();
    this.getListStates();
    this.getCurrencies();
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      order: [[1, 'desc']],
      ajax: (dataTablesParameters: any, callback) => {
        // dataTablesParameters.state = this.state;
        that.httpc
          .post<DataTablesResponse>(environment.api + '/support/orders/list', dataTablesParameters, {})
          .subscribe(res => {
            that.listorders = res.listorders;
            callback({
              recordsTotal: res.recordsTotal,
              recordsFiltered: res.recordsFiltered,
              data: [],
            });
          });
      },
      columns: [
        { data: 'companyName' },
        { data: 'orderId' },
        { data: 'state' },
        { data: 'submissionDate' },
        { data: 'purchaseType' },
        { data: 'redistribution' },
        { data: 'review', searchable: false, orderable: false },
      ]
    };
    this.dtOptions.search = { search: this.search };
  }

  filter(f) {
    this.search = f;
    // this.dtOptions.draw();
  }

  changeState(col) {

    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns(col).search(this.StateInput.value).draw();
    });
  }

  changeType(col) {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns(col).search(this.purchasetypeinput.value).draw();
    })
  }

  changeDate(event,col) {
    let date = this.datePipe.transform(event.value, 'yyyy-MM-dd');

    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => { dtInstance.columns(col).search(date).draw() })
  }

  onKey(event: any, col: number) {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns(col).search(event.target.value).draw();
    });
  }

  getListStates() {
    this.orderService.getListStates({}).subscribe(res => {
      this.states = res['states'];
    });
  }

  getStateName(stateId) {
    if (!this.states)
      return stateId;
    return this.states.filter(e => e.id === stateId)[0] ? this.states.filter(e => e.id === stateId)[0].name : stateId;
  }

  getOrders(){
    this.supportService.getOrders().subscribe(res => {
      this.orders = res.orders
    })
  }

  getCurrencies() {
    this.currencyService.getCurrencies().subscribe(r => {
      this.symbols = [];
      r.currencies.forEach(s => {
        this.symbols[s.id] = s.symbol;
      });
    });
  }

}

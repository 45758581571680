export class Product {
    item: number;
    dataSet: string;
    instrumentID: number;
    productID: number;
    symbol: string;
    description: string;
    assetClass: string;
    exchange: string;
    mic: string;
    purchaseType: PurchaseType;
    engagementPeriod: number;
    dateFrom: string;
    dateTo: string;
    pricingTier: number;
    price: string;
    exchangeFee: number;
    delivrables: any;
    historical_data: any;
    id_undercmd:any;
    idx: any;
    status:any;
    
    constructor(
        item: number,
        dataSet: string,
        instrumentID: number,
        productID: number,
        symbol: string,
        description: string,
        assetClass: string,
        exchange: string,
        mic: string,
        subscription: number,
        engagementPeriod: number,
        dateFrom: string,
        dateTo: string,
        pricingTier: number,
        price: string,
        backfillFee: number,
        ongoingFee: number,
        delivrables: any,
        historical_data:any,
        id_undercmd:any,
        idx: any,
        status:any) {
        this.item = item;
        this.dataSet = dataSet;
        this.instrumentID = instrumentID;
        this.productID = productID;
        this.symbol = symbol;
        this.description = description;
        this.assetClass = assetClass;
        this.exchange = exchange;
        this.mic = mic;
        this.purchaseType = subscription == 1 ? PurchaseType.subscription : PurchaseType.onetime;
        this.engagementPeriod = engagementPeriod;
        this.dateFrom = dateFrom;
        this.dateTo = dateTo;
        this.pricingTier = pricingTier;
        this.price = price;
        this.exchangeFee = subscription == 1 ? ongoingFee : backfillFee;
        this.delivrables = delivrables;
        this.historical_data = historical_data;
        this.id_undercmd = id_undercmd;
        this.idx = idx;
        this.status = status;
    }
}
export enum PurchaseType {
    subscription = 'Subscription', onetime = 'One-Off'
}

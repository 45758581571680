import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Route } from '@angular/router';

import { OrderService } from '../../../services/order.service';
import { ConfigService } from '../../../services/config.service';
import { CurrencyService } from '../../../services/currency.service';

import { DownloadInvoiceService } from '../../../services/Intern/download-invoice.service';
import { SwalAlertService } from '../../../../app/shared/swal-alert/swal-alert.service';
import { BurgerMenuService } from '../../../../app/shared/burger-menu/burger-menu.service';
import { BreadCrumbService } from '../../../services/bread-crumb.service';
import { ComplianceService } from '../../../services/compliance.service';
import { OrderAmount } from '../../order/models/order-amount.model';
import { OrderAmountBuilderService } from '../../../shared/order/order-amount-builder.service';

@Component({
    selector: 'app-ordersc-view',
    templateUrl: './ordersc-view.component.html',
    styleUrls: ['./ordersc-view.component.css']
})
export class OrderscViewComponent implements OnInit, OnDestroy {
    symbols: any[];
    symbol: string;
    message: string;
    action: string;
    cmd: any;
    idCmd: string;
    states: Array<any>;
    cart: Array<any>;

    actionButton: string = "ACTIONS";
    closeActionButton: string = "CLOSE";
    orderAmount: OrderAmount;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private configService: ConfigService,
        private currencyService: CurrencyService,
        private orderService: OrderService,
        private downloadInvoiceService: DownloadInvoiceService,
        private swalService: SwalAlertService,
        private burgerMenuService: BurgerMenuService,
        private breadCrumbService: BreadCrumbService,
        private complianceService: ComplianceService,
        private orderAmountBuilder: OrderAmountBuilderService
    ) {
        route.params.subscribe(_ => { this.idCmd = _.id; });
    }


    ngOnInit() {
        this.cart = [];
        this.symbols = [];
        this.message = '';
        this.action = '';
        this.symbol = '';
        this.getListStates();
        this.getCmd();

        this.complianceService.validateOrder.subscribe(result => {
            if (result) {
                this.validateOrder();
            }
        });
    }

    ngOnDestroy(): void {
        this.breadCrumbService.getOrderForView(null);
        this.complianceService.validateOrder.next(null);
    }
    getState(stateId) {
        if (!this.states) return stateId;
        return this.states.filter(state => state.id === stateId)[0] ? this.states.filter(state => state.id === stateId)[0].name : stateId;
    }

    getCmd() {
        this.orderAmount = <OrderAmount>{};
        this.currencyService.getCurrencies().subscribe(listOf => {
            this.symbols = [];
            listOf.currencies.forEach(listOfSymbols => { this.symbols[listOfSymbols.id] = listOfSymbols.symbol });
            this.complianceService.getInvoiceById(this.idCmd).subscribe((result) => {
                this.cmd = result;
                this.orderAmount = this.orderAmountBuilder.transformCmdToOrederAmount(this.cmd);
                //to send the functional id when the details page is reloded  
                this.breadCrumbService.getOrderForView(result);
                let index = 1;
                this.cmd.products.forEach(element => {
                    element.idx = index++
                });
            });
        });
    }


    verifState() {
        return this.cmd.state === 'PVC'
    }
    precisionRound(number, precision) {
        var factor = Math.pow(10, precision);
        return Math.round(number * factor) / factor;
    }

    dateDiff(firstDate, secondDate) {
        let calculatedDate = { sec: 0, min: 0, hour: 0, day: 0 };
        let dateDifference = secondDate - firstDate;
        dateDifference = Math.floor(dateDifference / 1000);
        calculatedDate.sec = dateDifference % 60;
        dateDifference = Math.floor((dateDifference - calculatedDate.sec) / 60);
        calculatedDate.min = dateDifference % 60;
        dateDifference = Math.floor((dateDifference - calculatedDate.min) / 60);
        calculatedDate.hour = dateDifference % 24;
        dateDifference = Math.floor((dateDifference - calculatedDate.hour) / 24);
        calculatedDate.day = dateDifference;
        return calculatedDate;
    }

    getListStates() {
        this.orderService.getListStates({}).subscribe(order => { this.states = order['states'] });
    }
    getStateName(stateId) {
        let specificState = this.states?.filter(state => state.id === stateId)[0]
        if (!this.states) return stateId;
        return specificState ? specificState.name : stateId;
    }

    downloadInvoice(invoice, pdfType) {
        this.downloadInvoiceService.getInvoice(this.cmd._id, invoice, pdfType);
    }

    async validateOrder() {
        var result = await this.swalService.getSwalForConfirm('Are you sure?', `You are going to validate order number <b> ${this.cmd.id}</b>`)
        if (result.value) {
            this.complianceService.StatusUpdate({ id: this.cmd._id, status: 'PVP', referer: 'Compliance', email: this.cmd['email'] })
                .subscribe(result => {
                    if (result) {
                        this.swalService.getSwalForNotification(`Order ${this.cmd.id} validated`, ` <b> Order ${this.cmd.id} validatd`),
                            error => {
                                this.swalService.getSwalForNotification('Validation Failed !', error.message, 'error')
                            }
                    }
                })
            this.router.navigate(['/compliance/orders']);
        }
    }


}

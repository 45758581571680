import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderState'
})
export class OrderStatePipe implements PipeTransform {

  transform(value: string): any {
    return value === "PVC" ? "pending validation by compliance" : value === "PVP" ? "pending validation by product" : value === "PVF" ? "pending validation by finance" : (value === "PLI" || value === "PSC" || value === "PBI") ? "cart" : value;
  }

}

import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, RouterEvent, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-administrator',
  templateUrl: './administrator.component.html',
  styleUrls: ['./administrator.component.css']
})
export class AdministratorComponent implements OnInit {
  currentComponents: string;

  constructor(private router: Router) {

  }


  ngOnInit(): void {
    this.currentComponents = this.router.url.slice(7)
    this.router.events.subscribe(route => {
      if (route instanceof NavigationStart) {
        this.currentComponents = route.url.slice(7);
      }
    })
  }


}

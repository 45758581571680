import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OrderService } from '../../../services/order.service';
import { OrderAmount } from '../models/order-amount.model';

@Component({
  selector: 'app-order-amount',
  templateUrl: './order-amount.component.html',
  styleUrls: ['./order-amount.component.css']
})
export class OrderAmountComponent implements OnInit {
  @Input() orderAmount: OrderAmount;
  @Input() symbol: any[];
  @Output() newDiscountValue = new EventEmitter<number>(null);

  toEdit: boolean = false;

  constructor(private orderService: OrderService) { }
  ngOnInit() {
    this.orderService.authoriseToEditDiscountOnOrderAmount.subscribe(toAuthorise => {
      this.toEdit = toAuthorise;
    });
  }


  setNewDiscountValue() {
    this.newDiscountValue.emit(this.orderAmount.discount);
    // this.orderService.newOrderAmountValues.subscribe(newOrderAmountValues => {
    //   this.orderAmount = newOrderAmountValues;
    // });
  }


}

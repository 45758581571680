import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';



import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FinanceService {

  orderToValidate = new BehaviorSubject<boolean>(null);
  orderToValidateForFinance = this.orderToValidate.asObservable();

  constructor(private http: HttpClient) { }

  orderToValidateByFinance() {
    this.orderToValidate.next(true);
  }

  getOrders() { return this.http.get<any>(environment.api + '/finance/orders')}
  getInvoiceById(orderId) { return this.http.get<any>(environment.api + '/finance/orders/getOrder/' + orderId); }
  getRoles() { return this.http.get<any>(environment.api + '/role') }

  financeStatusUpdate(order) {
    return this.http.put<any>(environment.api + '/finance/orders/statusUpdate', order)
  }

  exportPIR(firstId: number, lastId: number) {
    return this.http.post(environment.api + '/finance/pir/export', { minOrderId: firstId, maxOrderId: lastId }, { responseType: 'blob', observe: 'response' })
  }
  
  RefreshPIR(firstId: number, lastId: number) {
    return this.http.put(environment.api + '/finance/pir/refresh', { minOrderId: firstId, maxOrderId: lastId }, { responseType: 'blob', observe: 'response' })
  }
}

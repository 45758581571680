import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { InvoiceService } from '../../../services/invoice.service';
import { BreadCrumbService } from '../../../services/bread-crumb.service';
import { BurgerMenuService } from '../../../shared/burger-menu/burger-menu.service';
import { OrderService } from '../../../services/order.service';
import { SwalAlertService } from '../../../shared/swal-alert/swal-alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumb-for-list',
  templateUrl: './breadcrumb-for-list.component.html',
  styleUrls: ['./breadcrumb-for-list.component.css']
})
export class BreadcrumbForListComponent implements OnInit {

  editOff: boolean = false;;
  @Input() currentComponent: string;
  @Input() adminTask: string;
  @Input() order: any;
  @Input() id: string;
  @Input() route: string;
  @Input() hasRole: boolean;
  @Input() role: string;
  @Input() isAdmin: string;


  @Output() editOrderToClient = new EventEmitter<boolean>();
  @Output() validateOrderToCompliance = new EventEmitter<boolean>();
  @Output() validateOrderToFinance = new EventEmitter<boolean>();
  @Output() generateInvoiceToSupport = new EventEmitter<boolean>();
  @Output() cancelOrderForProduct = new EventEmitter<boolean>();
  @Output() rejectOrderForProduct = new EventEmitter<boolean>();
  @Output() validateOrderForProduct = new EventEmitter<boolean>();
  @Output() updateChangesForProduct = new EventEmitter<boolean>();
  @Output() applyDiscountForProduct = new EventEmitter<boolean>();
  @Output() updateEngagementPeriodForProduct = new EventEmitter<boolean>();
  @Output() getSwalToastNotificationForProduct = new EventEmitter<boolean>();
  @Output() cancelValidationForProduct = new EventEmitter<boolean>();

  @Output() orderToEditForProduct = new EventEmitter<boolean>();
  constructor(
    private burgerMenuService: BurgerMenuService
  ) { }

  ngOnInit(): void {
  }

  expandMenu() {
    let element = <HTMLElement>document.getElementById('toggle');
    this.burgerMenuService.toggleClass(element, 'on');
    return false;
  }

  verifStateForCompliance() {
    return this.order ? this.order.state === 'PVC' : false
  }

  verifStateForFinance() {
    return this.order ? this.order.state === 'PVF' : false
  }
  verifState() {
    if (!this.order) {
      return false;
    }
    else {
      let state = this.order.state;
      let pvp = 'PVP';
      let pvf = 'PVC';
      let pvc = 'PVF';
      if (state === pvc || state === pvp || state === pvf) return true;
      else return false;
    }
  }
  verifStatePVP() {
    if (!this.order) {
      return false;
    }
    else {
      let statesForCancel = ['PVP', 'PVF'];
      return statesForCancel.includes(this.order.state);
    }

  }

  editOrderForClient() {
    this.editOrderToClient.emit(true);
  }


  validateOrderForCompliance() {
    this.validateOrderToCompliance.emit(true);
  }

  validateOrderForFinance() {
    this.validateOrderToFinance.emit(true);
  }

  generateInvoiceForSupport() {
    this.generateInvoiceToSupport.emit(true);
  }

  cancelOrderByProduct() {
    this.cancelOrderForProduct.emit(true);
  }

  rejectOrderByProduct() {
    this.rejectOrderForProduct.emit(true);
  }

  validateOrderByProduct() {
    this.validateOrderForProduct.emit(true);
  }

  updateChangesByProduct() {
    this.updateChangesForProduct.emit(true);
  }

  cancelValidationByProduct() {
    this.cancelValidationForProduct.emit(true);
  }

  orderToEditByProduct() {
    this.toggleEdit();
    this.orderToEditForProduct.emit(this.editOff);
  }


  toggleEdit() {
    this.editOff = !this.editOff;
  }
}

import { Component, OnInit } from '@angular/core';
import { OrderService } from '../../../services/order.service';
import { BreadCrumbService } from '../../../services/bread-crumb.service';
import { DatePipe } from '@angular/common';
import { ComplianceService } from '../../../services/compliance.service';

@Component({
  selector: 'app-ordersc',
  templateUrl: './ordersc.component.html',
  styleUrls: ['./ordersc.component.css'],
  providers: [DatePipe]
})
export class OrderscComponent implements OnInit {
  state: string;
  states: Array<any>;
  orders: Array<object>;

  constructor(
    private orderService: OrderService,
    private complianceService: ComplianceService,
    private breadCrumbService: BreadCrumbService,
  ) { }


  ngOnInit() {
    this.state = 'PVC';
    this.getOrders();
    this.breadCrumbService.getOrderForView(null);
    this.getListStates();
  }
  getOrders() {
    this.complianceService.getOrders().subscribe(
      result => {
        this.orders = result.orders;
      }
    );
  }
  getListStates() {
    this.orderService.getListStates({}).subscribe(res => {
      this.states = res['states'];
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { SwalAlertService } from '../../../shared/swal-alert/swal-alert.service';
import { BreadCrumbService } from '../../../services/bread-crumb.service';
import { ProductService } from '../../../services/product.service';

@Component({
    selector: 'app-order-renewal',
   
    templateUrl: './order-renewal.component.html',
    styleUrls: ['./order-renewal.component.css']
})
export class OrderRenewalComponent implements OnInit {

    public order: any;
    orderId: string;
    orderColumns = ['item', 'productID', 'exchange', 'dateFrom', 'dateTo'];
    extendedStartDate: Date;
    extendedEndDate: Date;
    offsetInMintuesFormatted: number;


    /* display a spinner on the button while updating */
    displaySpinner = false;
    /* a flag to raise when the order is updated */
    orderUpdated = false;

    constructor(private swalService: SwalAlertService,
        private productService: ProductService,
        private breadCrumbService: BreadCrumbService
    ) { }

    ngOnInit(): void {

        this.orderId = this.productService.selectedExpiredOrder.longId;
        this.order = this.productService.selectedExpiredOrder;

        if (this.order) {
            // add item field staring from 1
            this.order.products = this.order.products.map((product: any, index: number) => ({ item: index + 1, ...product }));
            this.setDefaultRenewalDates();
        }
    }

    setDefaultRenewalDates(): void {

        const end_date = new Date(this.order.end_date);

        const offsetInMinutes = new Date().getTimezoneOffset();
        this.offsetInMintuesFormatted = offsetInMinutes < 0 ? Math.abs(offsetInMinutes) : -offsetInMinutes;

        this.extendedStartDate = new Date(end_date.getFullYear(), end_date.getMonth(), end_date.getDate() + 1, 0, this.offsetInMintuesFormatted)
        this.extendedEndDate = new Date(end_date.getFullYear() + 1, end_date.getMonth(), end_date.getDate(), 0, this.offsetInMintuesFormatted);
    }

    renewOrder() {

        this.displaySpinner = true;
        // we need to add the offset again because date picker will reset it to utc time 
        this.extendedEndDate.setMinutes(this.offsetInMintuesFormatted);

        const fromDate = this.extendedStartDate.toISOString().slice(0, 10);
        const toDate = this.extendedEndDate.toISOString().slice(0, 10);

        this.productService.extendExpiringOrderByOneYear(this.orderId, fromDate, toDate)
            .subscribe(() => {
                this.displaySpinner = false;
                this.swalService.getSwalForNotification("order updated", "");
                this.order.end_date = toDate;
                this.orderUpdated = true;
            })
    }

    ngOnDestroy(): void {
        this.breadCrumbService.getOrderForView(null);
    }
}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PIRExportComponent } from '../pirexport/pirexport.component';
import { RefreshPIRComponent } from '../refresh-pir/refresh-pir.component';

@Component({
  selector: 'app-menu-finance',
  templateUrl: './menufinance.component.html',
  styleUrls: ['./menufinance.component.css']
})
export class MenufinanceComponent implements OnInit {

  pageConfig: { page: string; active: boolean; link: string; }[];
  constructor(public dialog: MatDialog) { }

  ngOnInit() {
    this.pageConfig = [
      { page: 'Client Orders', active: false, link: '/finance/orders' },
      { page: 'Settings', active: false, link: '/finance/config' }
    ];
  }

  openExportDialog() {
    this.dialog.open(PIRExportComponent, {});
  }

  openRefreshPIRDialog() {
    this.dialog.open(RefreshPIRComponent, {});
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowErrorsComponent } from './show-error/show-errors.component';
import { NgbdModalContent } from './modal-content/modal-content';
import { RouterModule } from '@angular/router';
import { BillingInformationsComponent } from './user/billing-informations/billing-informations.component';
import { MaterialModule } from '../modules/material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactInformationsComponent } from './user/contact-informations/contact-informations.component';
import { PasswordInformationsComponent } from './user/password-informations/password-informations.component';
import { CookiesComponent } from './cookies/cookies.component';
import { ClientViewComponent } from './user/client-view/client-view.component';
import { OrderModule } from '../modules/order/order.module';
import { ClientInformationComponent } from './user/client-information/client-information.component';
import { PipesModule } from '../pipes/pipes.module';
import { ListOrdersComponent } from './list-orders/list-orders.component';
import { LinksViewComponent } from './links-view/links-view.component';
import { ListMicsComponent } from './list-mics/list-mics.component';



@NgModule({
  declarations: [
    ShowErrorsComponent,
    NgbdModalContent,
    BillingInformationsComponent,
    ContactInformationsComponent,
    PasswordInformationsComponent,
    ClientInformationComponent,
    CookiesComponent,
    ClientViewComponent,
    ListOrdersComponent,
    LinksViewComponent,
    ListMicsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    OrderModule,
    PipesModule
    ],
  entryComponents: [
    NgbdModalContent,
    LinksViewComponent,
  ],

  exports: [
    ShowErrorsComponent,
    NgbdModalContent,
    BillingInformationsComponent,
    ContactInformationsComponent,
    PasswordInformationsComponent,
    ClientInformationComponent,
    ClientViewComponent,
    ListOrdersComponent,
    LinksViewComponent,
    ListMicsComponent
  ]
})
export class SharedModule { }

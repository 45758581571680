import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-config-elastic',
  templateUrl: './config-elastic.component.html',
  styleUrls: ['./config-elastic.component.css']
})
export class ConfigElasticComponent implements OnInit {

  constructor() { }

  ngOnInit() { }
}

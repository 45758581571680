import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataSet'
})
export class DataSetPipe implements PipeTransform {

  transform(value: string): string {
    return (value === "L1TRADEONLY") ? "L1 - Trades" : (value === "L1") ? "L1 - Full" : value;
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ComplianceService {

  validateOrder = new BehaviorSubject<boolean>(null);
  toValidateOrder = this.validateOrder.asObservable();
  constructor(private http: HttpClient) { }

  validateOrderForCompliance(){
    this.validateOrder.next(true);
  }

  StatusUpdate(order) { return this.http.put<any>(environment.api + '/compliance/orders/statusUpdate', { order }) }

  getInvoiceById(id) { return this.http.get<any>(environment.api + '/compliance/orders/getOrder/' + id) }

  getOrders() { return this.http.get<any>(environment.api + '/compliance/orders/list')}
}

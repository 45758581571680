import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search/search.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { TemplatesModule } from '../templates/templates.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { searchRoutes } from './search.routing';
import { MaterialModule } from '../material/material.module';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';




@NgModule({
  declarations: [
    SearchComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TemplatesModule,
    NgbModule,
    MaterialModule,
    RouterModule.forRoot(searchRoutes)

  ],
  exports: [
    SearchComponent,
    RouterModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' }
  ]
})
export class SearchModule { }

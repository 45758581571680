import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  liksToGenereteBySupport = new BehaviorSubject<boolean>(null)
  linksToGenerateForSupport = this.liksToGenereteBySupport.asObservable();

  constructor(private http: HttpClient) { }

  getOrders() {
    return this.http.get<any>(environment.api + "/support/orders");
  }
  getLinks(orderId) {
    return this.http.post<any>(environment.api + "/support/deliverables/links", { orderId: orderId })
  }
  getLink(orderId, productId) {
    return this.http.post<any>(environment.api + "/support/deliverables/link", { orderId: orderId, productId: productId })
  }
  linksToGenerate() {
    this.liksToGenereteBySupport.next(true);
  }

  getSupportOrderDetailsById(orderId) { return this.http.get<any>(environment.api + '/support/orders/details/' + orderId) }

  getSupportLogsOrdersByOrderId(orderId) { return this.http.get<any>(environment.api + '/support/orderProductLog/' + orderId) }

  getSupportLogsOrderByProductId(orderId, productId) {
    return this.http.get<any>(environment.api + '/support/orderProductLog/' + orderId + '/' + productId);
  }

  getRetry(id, mode) { return this.http.get<any>(environment.api + '/support/orders/retry/' + id + '/' + mode) }

  validateOrderCompliance(order) { return this.http.put<any>(environment.api + '/support/orders/validateComplianceBySupport', { order }) }
}

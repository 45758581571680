import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.css']
})
export class CookiesComponent implements OnInit {

  hasAccepted: boolean = false;

  constructor(private dialog: MatDialog, private cookieService: CookieService) {
   }

  ngOnInit(): void {    
  }

  acceptAndClose() {
    this.cookieService.set('cookies', 'test');
    this.cookieService.get('cookies');
    this.dialog.closeAll();
  }

}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateTimeToStringConverterService {
  constructor() { }
  convertDateToString(date) {
    try {
      let day = date.getDate().toString().length < 2 ? `0${date.getDate()}` : date.getDate();
      let month = (date.getMonth() + 1).toString().length < 2 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
      let newDate = `${date.getFullYear()}-${month}-${day}`;
      return newDate;
    }
    catch (error) {
    }
  }
}

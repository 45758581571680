import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../../services/product.service';
import { BreadCrumbService } from '../../../services/bread-crumb.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  currentComponent: any;
  splitedroute: string[];
  id: string;
  order: any;

  constructor(private productService: ProductService, private breadCrumbService: BreadCrumbService) { }

  ngOnInit(): void {
    this.breadCrumbService.orderForBreadCrumb.subscribe(order => {
      if (!order) {
        this.id = "";
        return;
      }
      this.id = order.id;
      this.order = order;
    });
  }


  cancelOrderForProduct(response) {
    if (response) {
      this.productService.cancelOrderByProduct();
    }
  }
  rejectOrderForProduct(response) {
    if (response) {
      this.productService.rejectOrderByProduct();
    }
  }
  validateOrderForProduct(response) {
    if (response) {
      this.productService.validateOrderByProduct();
    }
  }
  updateChangesForProduct(response) {
    if (response) {
      this.productService.updateChangesByProduct();
    }
  }


  cancelValidationForProduct(response) {
    if (response) {
      this.productService.cancelValidationByProduct();
    }
  }

  orderToEditForProduct(editOff) {
      this.productService.orderToEditByProduct(editOff)
    
  }

}

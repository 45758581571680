import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../../services/product.service';


@Component({
    selector: 'app-expiring-orders',
    templateUrl: './expiring-orders.component.html',
    styleUrls: ['./expiring-orders.component.css']
})
export class ExpiringOrdersComponent implements OnInit {


    orders: Array<object>;

    constructor(private productService: ProductService) { }

    displaySpinner = false;
    ordersLoaded = false;

    ngOnInit(): void {
        this.getExpiringOrders();
    }

    getExpiringOrders() {
        this.productService.getExpiringOrders().subscribe(orders => {

            if (orders && orders.length > 0) {

                const formatedOrders = [];
                for (let order of orders) {

                    const end_date = order.products[0].end_date;
                    const begin_date = order.products[0].begin_date;
                    const formatedProductOrder = {
                        longId: order._id, // mongoDBId
                        id: order.id,
                        companyName: order.companyName,
                        begin_date,
                        end_date,
                        products: order.products
                    }
                    const timeDiff = new Date(end_date).getTime() - new Date().getTime();
                    formatedProductOrder['days_until_expiration'] = Math.round(timeDiff / (1000 * 60 * 60 * 24));
                    formatedOrders.push(formatedProductOrder);
                }
                this.orders = formatedOrders;
                this.productService.expiringOrders = this.orders;
            }
            this.ordersLoaded = true;
        })
    }
}

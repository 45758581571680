import { Component, OnInit, Input, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { AuthentificationService } from '../../../services/authentification.service';
import { Subscription } from 'rxjs';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit, OnDestroy {
  link: any = "";
  roles: string[] = new Array<string>();
  private authSubscription: Subscription;
  role: any;
  constructor(
    private router: Router,
    private authService: AuthentificationService,
    private userService: UserService
  ) {

  }

  ngOnDestroy(): void {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.authSubscription = this.authService.subscribe(event => this.onAuthEventEmitted(event))
    // this.logoutEvent.subscribe(event => this.logout());
    this.roles = this.authService.getRoles();
    this.role = "Roles"
  }

  logout() {
    this.authService.logout().subscribe(res => {
      this.ngOnInit();
      this.router.navigate(['/home']);

    });
    //this.router.navigate(['/home']);
  }

  onAuthEventEmitted(eventParameters) {
    this.roles = this.authService.getRoles();
    // if (eventParameters && eventParameters.event === 'logout') {
    // this.role = "";
    // this.router.navigated = false;
    // this.router.navigate(['/home']);
    // }
  }

  getRole(event) {

    this.router
    this.role = event.target.innerText;
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { CookiesComponent } from './shared/cookies/cookies.component';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

    hasAccepted: boolean = false;

    constructor(private dialog: MatDialog, private cookieService: CookieService) {
    }

    ngOnInit() {
        const cookieExists: boolean = this.cookieService.check('cookies');
        if (!cookieExists) {
            this.openCookiesComponement();
        }
    }

    openCookiesComponement() {
        this.dialog.open(CookiesComponent, {
            disableClose: true,
        });
    }

}

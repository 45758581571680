import { Component, OnInit, Input } from '@angular/core';
import { CountriesService } from '../../../services/countries.service';
import { ClientInformation } from '../../../modules/client/models/client-information.model';

@Component({
  selector: 'app-client-information',
  templateUrl: './client-information.component.html',
  styleUrls: ['./client-information.component.css']
})
export class ClientInformationComponent implements OnInit {

  @Input() clientInfo: ClientInformation;
  countryName: string;

  constructor(private countryService: CountriesService) { }
  ngOnInit() { 
    this.getCountryName(this.clientInfo.countryBilling);
  }

  getCountryName(countryId){
    this.countryService.getCountry(countryId).subscribe(res => {
      this.countryName =  res.country.name;
    })
  }
}

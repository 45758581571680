import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { PipesModule } from '../../pipes/pipes.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { RouterModule } from '@angular/router';
import { TemplatesModule } from '../templates/templates.module';
import { OrderspViewComponent } from './orders-view/ordersp-view.component';
import { VatComponent } from './vat/vat.component';
import { AdtvComponent } from './adtv/adtv.component';
import { ConfigpComponent } from './config/configp.component';
import { DownloadSettingComponent } from './download-setting/download-setting.component';
import { MailFailedComponent } from './mail-failed/mail-failed.component';
import { MenuproductComponent } from './menuproduct/menuproduct.component';
import { OrderspComponent } from './orders/ordersp.component';

import { PaymentsComponent } from './payments/payments.component';
import { productRoutes } from './product.routing';
import { ProductComponent } from './product/product.component';
import { OrderModule } from '../order/order.module';
import { SharedModule } from '../../shared/shared.module';
import { ListExpiringOrdersComponent } from './list-expiring-orders/list-expiring-orders.component';
import { OrderRenewalComponent } from './order-renewal/order-renewal.component';
import { ExpiringOrdersComponent } from './expiring-orders/expiring-orders.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatNativeDateModule } from '@angular/material/core';

@NgModule({
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-US' }
    ],
    declarations: [
        AdtvComponent,
        ConfigpComponent,
        DownloadSettingComponent,
        MailFailedComponent,
        MenuproductComponent,
        OrderspComponent,
        ExpiringOrdersComponent,
        OrderRenewalComponent,
        OrderspViewComponent,
        PaymentsComponent,
        VatComponent,
        ProductComponent,
        ListExpiringOrdersComponent
    ],
    imports: [
        CommonModule,
        TemplatesModule,
        RouterModule.forRoot(productRoutes),
        DataTablesModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        PipesModule,
        MaterialModule,
        OrderModule,
        SharedModule,
        MatNativeDateModule
    ],
    exports: [
        AdtvComponent,
        ConfigpComponent,
        DownloadSettingComponent,
        MailFailedComponent,
        MenuproductComponent,
        OrderspComponent,
        OrderspViewComponent,
        PaymentsComponent,
        VatComponent,
        RouterModule
    ]
})
export class ProductModule { }

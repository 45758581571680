import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AdministratorServiceService } from '../../../services/administrator-service.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SwalAlertService } from '../../../shared/swal-alert/swal-alert.service';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  user: any;
  userToUpdate: any;
  users: Array<object>;
  displayedColumns: string[] = ['firstname', 'lastname', 'companyName'];
  dataSource: MatTableDataSource<any>;
  totalCount: number;
  selectedUser: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private adminsitratorService: AdministratorServiceService, private swalService: SwalAlertService) { }

  @ViewChild('utilisateurForm', { static: false })

  ngOnInit() {
    this.getUsers();
  }
  getUser(id) {
    if (this.user._id != id) {
      this.adminsitratorService.getUserById(id).subscribe(res => {
        this.user = res.user;
        this.selectedUser = this.user._id;
      });
    }
  }

  getUsersSorted(event?: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getUsers() {
    this.adminsitratorService.getUsers().subscribe(res => {
      this.users = res.users;
      this.user = this.users[0];
      this.selectedUser = this.user?._id;
      this.dataSource = new MatTableDataSource(this.users);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  sendAccountActivationMail(id,email){
    this.swalService.getSwalForConfirm(`Activate user account`,`Send mail to activate <b> ${email} </b> account`,"info").then(result => {
      if(result.isConfirmed){
        this.adminsitratorService.regenrateTokenForAccountActivation(id).subscribe(res => {
          this.swalService.getSwalForConfirm(`Activate user account`, `A new mail has been sent to ${email}`, "success",false);
        },
        error => {
          this.swalService.getSwalForConfirm(`Activate <b> ${email} </b> account`,error.message,"error",false);
        });
      }
    });
  }
}

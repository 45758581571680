import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CountriesService {

    constructor(private http: HttpClient) { }

    getCountries() { return this.http.get<any>(environment.api + '/countries') }
    getCountry(countryId: string) { return this.http.get<any>(environment.api + '/countries/' + countryId) }


    isUE(id: any) { return this.http.post<any>(environment.api + '/countries/isUE', id) }

    saveUE(country) { return this.http.put<any>(environment.api + '/countries/ue', country) }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProductService {


    orderToCancel = new BehaviorSubject<boolean>(null);
    cancelOrderForProduct = this.orderToCancel.asObservable();

    orderToReject = new BehaviorSubject<boolean>(null);
    rejectOrderForProduct = this.orderToReject.asObservable();

    orderToValidate = new BehaviorSubject<boolean>(null);
    validateOrderForProduct = this.orderToValidate.asObservable();

    orderToUpdateChanges = new BehaviorSubject<boolean>(null);
    updateChangesForProduct = this.orderToUpdateChanges.asObservable();


    orderToApplyDiscount = new BehaviorSubject<boolean>(null);
    applyDiscountForProduct = this.orderToApplyDiscount.asObservable();

    orderToUpdateEngagementPeriod = new BehaviorSubject<boolean>(null);

    updateEngagementPeriodForProduct = this.orderToUpdateEngagementPeriod.asObservable();

    orderTogetSwalToastNotification = new BehaviorSubject<boolean>(null);
    getSwalToastNotificationForProduct = this.orderTogetSwalToastNotification.asObservable();

    orderToCancelValidation = new BehaviorSubject<boolean>(null);
    cancelValidationForProduct = this.orderToCancelValidation.asObservable();

    orderToEdit = new BehaviorSubject<boolean>(null);
    editOrderForProduct = this.orderToEdit.asObservable();


    // used by order renewal component

    // list of expiring orders
    public expiringOrders = [];
    public selectedExpiredOrder: any;


    constructor(private http: HttpClient) { }


    cancelOrderByProduct() {
        this.orderToCancel.next(true);
    }

    rejectOrderByProduct() {
        this.orderToReject.next(true);
    }

    validateOrderByProduct() {
        this.orderToValidate.next(true);
    }

    updateChangesByProduct() {
        this.orderToUpdateChanges.next(true);
    }

    applyDiscountByProduct() {
        this.orderToApplyDiscount.next(true);
    }

    updateEngagementPeriodByProduct() {
        this.orderToUpdateEngagementPeriod.next(true);
    }

    getSwalToastNotificationByProduct() {
        this.orderTogetSwalToastNotification.next(true);
    }

    cancelValidationByProduct() {
        this.orderToCancelValidation.next(true);
    }

    orderToEditByProduct(editOff) {
        this.orderToEdit.next(editOff);
    }


    updateDiscount(order) { return this.http.put<any>(environment.api + '/product/orders/updateDiscount', order) }

    updateEngagementPeriod(order) { return this.http.put<any>(environment.api + '/product/orders/updateEngagementPeriod', order) }

    SaveOrderMetadata(orderId: number, internalNote: string, sales: string, OrderType: string) {
        return this.http.put<any>(environment.api + '/product/orders/updatemetadata',
            { id: orderId, note: internalNote, sales: sales, type: OrderType })
    }

    StatusUpdate(order) { return this.http.put<any>(environment.api + '/product/orders/statusUpdate', order) }

    CancelOrder(order) { return this.http.put<any>(environment.api + '/product/orders/cancelOrder', order) }

    RejectOrder(order) { return this.http.put<any>(environment.api + '/product/orders/rejectOrder', order) }

    cancelProductValidation(orderId) { return this.http.put<any>(environment.api + '/product/orders/cancelValidation', { id: orderId }) }

    getOrders() {
        return this.http.get<any>(environment.api + '/product/orders/');
    }

    getExpiringOrders() {
        return this.http.get<any>(`${environment.api}/product/orders/expiring`);
    }

    extendExpiringOrderByOneYear(orderId: string, fromDate: string, toDate: string) {
        return this.http.post<any>(`${environment.api}/product/orders/renew/${orderId}`, { fromDate, toDate });
    }
    
    getOrderById(orderId: string) { return this.http.get<any>(environment.api + '/product/orders/getOrder/' + orderId); }

}
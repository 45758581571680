import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-list-orders',
    templateUrl: './list-orders.component.html',
    styleUrls: ['./list-orders.component.css']
})
export class ListOrdersComponent implements OnInit {

    @ViewChild("SDate") submitdateinput: ElementRef;

    @ViewChild("PType") purchasetypeinput: any;
    @Input() state: string;
    defaultState: string = 'Pending Validation By Compliance';
    @Input() states: Array<any>;
    @Input() orders: Array<object>;
    @Input() symbols: any[];
    displayedColumns: string[] = ['client', 'orderId', 'orderStatus', 'submissionDate', 'totalAmount', 'purchaseType', 'discount', 'redistribution', 'review'];
    dataSource: MatTableDataSource<any>;

    @ViewChild(MatPaginator) set paginator(value: MatPaginator) { this.dataSource.paginator = value; }
    @ViewChild(MatSort) set sort(value: MatSort) { this.dataSource.sort = value; }
    orderIdFilter = new FormControl('');
    totalFilter = new FormControl('');
    discountFilter = new FormControl('');
    clientFilter = new FormControl('');
    filteredValues: any = { orderId: '', companyName: '', submissionDate: '', redistribution: '', state: '', purchaseType: '', total: '', discount: '' }
    routingMap = new Map<string, string>([
        ["PVC", "/compliance/orderview/"],
        ["PVP", "/product/orderview/"],
        ["PVF", "/finance/orderview/"],
        ["SUPPORT", "/support/clientorder/"]
    ]);
    constructor(private datePipe: DatePipe) { }

    ngOnInit(): void {
        this.checkDisplayedColumns();
        this.dataFilterControl();
        this.listOrders();
    }

    listOrders() {
        this.dataSource = new MatTableDataSource(this.orders);
        this.dataSource.filterPredicate = this.filterPredicate();
        if (this.state) {
            this.filteredValues['state'] = this.state;
        }
        this.dataSource.filter = JSON.stringify(this.filteredValues);
    }


    stateFilter(event) {
        this.filteredValues['state'] = event.value.trim().toLowerCase();
        this.dataSource.filter = JSON.stringify(this.filteredValues);
    }

    purchaseTypeFilter(event) {
        this.filteredValues['purchaseType'] = event.value.trim().toLowerCase();
        this.dataSource.filter = JSON.stringify(this.filteredValues);
    }

    redistributionFilter(event) {
        this.filteredValues['redistribution'] = event.value.trim().toLowerCase();
        this.dataSource.filter = JSON.stringify(this.filteredValues);
    }

    dateFilter(event) {
        this.filteredValues['submissionDate'] = "";
        if (event.value) {
            let date = this.datePipe.transform(event.value, 'yyyy-MM-dd');
            this.filteredValues['submissionDate'] = date;
        }
        this.dataSource.filter = JSON.stringify(this.filteredValues);
    }


    getStateName(stateId) {
        if (!this.states)
            return stateId;
        return this.states.filter(e => e.id === stateId)[0] ? this.states.filter(e => e.id === stateId)[0].name : stateId;
    }


    private dataFilterControl() {
        this.orderIdFilter.valueChanges.subscribe((orderIdFilterValue) => {
            this.filteredValues['orderId'] = orderIdFilterValue;
            this.dataSource.filter = JSON.stringify(this.filteredValues);
        });

        this.clientFilter.valueChanges.subscribe((companyNameFilterValue) => {
            this.filteredValues['companyName'] = companyNameFilterValue;
            this.dataSource.filter = JSON.stringify(this.filteredValues);
        });

        this.totalFilter.valueChanges.subscribe((companyNameFilterValue) => {
            this.filteredValues['total'] = companyNameFilterValue;
            this.dataSource.filter = JSON.stringify(this.filteredValues);
        });

        this.discountFilter.valueChanges.subscribe((companyNameFilterValue) => {
            this.filteredValues['discount'] = companyNameFilterValue;
            this.dataSource.filter = JSON.stringify(this.filteredValues);
        });
    }

    filterPredicate(): (data: any, filter: string) => boolean {
        let filterFunction = function (data, filter): boolean {
            let searchTerms = JSON.parse(filter);
            let purchaseTypeExist = true;
            if (searchTerms.purchaseType) {
                purchaseTypeExist = data.purchaseType.toLowerCase() === searchTerms.purchaseType.toLowerCase();
            }
            return data.orderId.toString().toLowerCase().indexOf(searchTerms.orderId) !== -1 &&
                data.companyName.toString().trim().toLowerCase().indexOf(searchTerms.companyName.toLowerCase()) !== -1 &&
                data.submissionDate.toString().trim().toLowerCase().indexOf(searchTerms.submissionDate.toLowerCase()) !== -1 &&
                data.survey[0].dd.toString().trim().toLowerCase().indexOf(searchTerms.redistribution.toLowerCase()) !== -1 &&
                data.state.toString().trim().toLowerCase().indexOf(searchTerms.state.toLowerCase()) !== -1 &&
                data.total.toString().trim().toLowerCase().indexOf(searchTerms.total.toLowerCase()) !== -1 &&
                data.discount.toString().trim().toLowerCase().indexOf(searchTerms.discount.toLowerCase()) !== -1 &&
                purchaseTypeExist;
        }
        return filterFunction;
    }

    checkDisplayedColumns() {
        if (this.state === "PVC") {
            this.displayedColumns = this.displayedColumns.filter(item =>
                item !== "totalAmount" && item !== "discount"
            );
        }
    }
    getRouting(orderIdReference: string) {
        if (!this.state) {
            return this.routingMap.get("SUPPORT") + orderIdReference;
        }
        return this.routingMap.get(this.state) + orderIdReference;
    }

}

import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { OrderService } from '../../../services/order.service';
import { CurrencyService } from '../../../services/currency.service';

import { environment } from '../../../../environments/environment';
import { OrderDetails } from '../../../core/models/order-details.model';
import { DownloadInvoiceService } from '../../../services/Intern/download-invoice.service';
import { InvoiceService } from '../../../services/invoice.service';
import { BreadCrumbService } from '../../../services/bread-crumb.service';
import { SupportService } from '../../../services/support.service';
import { DecodeDataService } from '../../../services/decode-data.service';
import { SwalAlertService } from '../../../shared/swal-alert/swal-alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { RetryDeltaModalComponent } from '../retry-delta-modal/retry-delta-modal.component';

@Component({
  selector: 'app-client-order-details',
  templateUrl: './client-order-details.component.html',
  styleUrls: ['./client-order-details.component.css']
})
export class ClientOrderDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

  idCmd: string;
  gateway: string;
  token: any;
  symbols: any[];
  symbol: string;
  message: string;
  states: Array<any>;
  cart: Array<any>;
  item: any;
  period: any;
  datasets: object;
  datasetsLink: { L1: string; L1TRADEONLY: string; L2: string; };
  dtOptions: DataTables.Settings = {};
  orderDetails: OrderDetails;
  actionButton: string = "ACTIONS";
  closeActionButton: string = "CLOSE";
  cmd: any;
  order: any;



  constructor(
    route: ActivatedRoute,
    private currencyService: CurrencyService,
    private orderService: OrderService,
    private downloadInvoiceService: DownloadInvoiceService,
    private invoiceService: InvoiceService,
    private decodeDataService: DecodeDataService,
    private breadCrumbService: BreadCrumbService,
    private supportService: SupportService,
    private swalService: SwalAlertService,
    public dialog: MatDialog,
    private router: Router,
  ) {
    route.params.subscribe(_ => { this.idCmd = _.id; });
  }
  ngAfterViewInit(): void {
    this.loadOrderDetailsAndSetupOrderDetails();
  }


  ngOnInit() {
    this.listCurrencies();
    this.gateway = environment.gateway;
    this.item = false;
    this.cart = [];
    this.symbols = [];
    this.message = '';

    this.datasets = {
      L1: 'L1 - Full',
      L1TRADEONLY: 'L1 - Trades',
      L2: 'L2'
    };

    this.getListStates();
    this.supportService.linksToGenerateForSupport.subscribe(response => {
      if (response) {
        this.generateInvoice()
      }
    });
  }
  ngOnDestroy(): void {
    this.breadCrumbService.getOrderForView(null);
    this.supportService.liksToGenereteBySupport.next(null)
  }

  getOrderDetails(order: any) {
    this.currencyService.getCurrencies().subscribe(r => {
      this.symbols = [];
      r.currencies.forEach(s => {
        this.symbols[s.id] = s.symbol;
      });
      this.cmd = order;
      this.cart = [];

      //to send the functional id when the details page is reloded  
      this.breadCrumbService.getOrderForView(order);

      let index = 1;
      //send id commande to genrate invoice
      this.cmd.products.forEach(element => {
        element.idx = index++
      });
    });
  }

  listCurrencies() {
    this.currencyService.getCurrencies().subscribe(list => {
      this.symbols = [];
      list.currencies.forEach(item => {
        this.symbols[item.id] = item.symbol;
      });
    });
  }

  getListStates() {
    this.orderService.getListStates({}).subscribe(res => {
      this.states = res['states'];
    });
  }

  verifState() {
    if (this.cmd.state === 'PVC') {
      return true;
    } else {
      return false;
    }
  }

  downloadInvoice(invoice, pdfType) {
    this.downloadInvoiceService.getInvoice(this.cmd._id, invoice, pdfType);
  }

  generateInvoice() {
    this.invoiceService.generateInvoice(this.cmd._id).subscribe(res => {
      let text = `Invoice for order N°  ${this.order.id} generated succefully`;
      this.swalService.getSwalForConfirm("Generate invoice", text, "success", false);
    });
  }

  private loadOrderDetailsAndSetupOrderDetails() {
    this.supportService.getSupportOrderDetailsById(this.idCmd).subscribe(order => {
      this.order = order.details;
      this.SetupOrderDetails(this.order);
    });
  }

  private SetupOrderDetails(order: any) {
    this.orderDetails = this.convertOrderToOrderDetails(order);
    this.getOrderDetails(this.orderDetails.details);

  }

  convertOrderToOrderDetails(order) {
    var orderDetails = new OrderDetails(order, order.products);
    orderDetails.details.token = this.decodeDataService.decodeData(order.userToken);
    return orderDetails;
  }

  openRetryDeltaDialog(data) {
    const dialogRef = this.dialog.open(RetryDeltaModalComponent, {
      data: {
        retryMode: data.retryMode,
        itemReference: data.itemReference
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadOrderDetailsAndSetupOrderDetails();
      }
    });
  }
  getProductLogs(product) {
    this.item = product;
    if (this.item.purchaseType === "One-Off") {
      this.item.reference = this.item.id_undercmd;
    }
    this.supportService.getSupportLogsOrderByProductId(this.idCmd, product.idx).subscribe(result => {
      this.item.logs = result.logs
    });
  }

  async validateOrderCompliance() {
    var result = await this.swalService.getSwalForConfirm('Are you sure?', `You are going to validate order number <b> ${this.cmd.id}</b>`)
    if (result.value) {
      this.supportService.validateOrderCompliance({ id: this.cmd._id, status: 'PVP', referer: 'Compliance', email: this.cmd['email'] })
        .subscribe(result => {
          if (result) {
            this.swalService.getSwalForNotification(`Order ${this.cmd.id} validated`, ` <b> Order ${this.cmd.id} validatd`),
              error => {
                this.swalService.getSwalForNotification('Validation Failed !', error.message, 'error')
              }
          }
        })
      this.router.navigate(['/compliance/orders']);
    }
  }

}

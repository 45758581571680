import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeliverablesService {

  constructor(private http: HttpClient) { }

  getLinks(orderId) {
    return this.http.get<any>(environment.api + "/deliverables/links/" + orderId)
  }
  getLink(orderId, productId): Observable<any> {
    return this.http.post<any>(environment.api + "/deliverables/link", { orderId: orderId, productId: productId })
  }
}


import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { OrderService } from '../../../services/order.service';
import { CurrencyService } from '../../../services/currency.service';

import { SalesService } from '../../../services/sales.service';
import { DownloadInvoiceService } from '../../../services/Intern/download-invoice.service';
import { SwalAlertService } from '../../../shared/swal-alert/swal-alert.service';
import { BreadCrumbService } from '../../../services/bread-crumb.service';
import { ProductService } from '../../../services/product.service';
import { OrderAmount } from '../../order/models/order-amount.model';
import { OrderAmountBuilderService } from '../../../shared/order/order-amount-builder.service';
import { OrderInformation } from '../../order/models/order-information.model';
import { ClientInformation } from '../../client/models/client-information.model';
import { OrderInformationsBuilderService } from '../../../shared/order/order-informations-builder.service';
import { ClientInformationsBuilderService } from '../../../shared/order/client-informations-builder.service';
import { MatTableDataSource } from '@angular/material/table';
import { ConfigService } from '../../../services/config.service';

@Component({
    selector: 'app-ordersp-view',
    templateUrl: './ordersp-view.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./ordersp-view.component.css']
})
export class OrderspViewComponent implements OnInit, OnDestroy {
    existSubscribe: boolean;
    symbols: any[];
    symbol: string;
    action: string;
    cmd: any;
    idCmd: string;
    submissionDate: string;
    states: Array<any>;
    cart: Array<any>;
    reason: any;
    invoice: string;
    proForma: string;
    listSales: string[] = [];
    choosedOrderType: string;
    editOff: boolean;
    options = {
        autoClose: true,
        keepAfterRouteChange: false
    };
    actionButton: string = "ACTIONS";
    closeActionButton: string = "CLOSE";
    totalHT: any;
    period: any;
    orderAmount: OrderAmount;
    orderInfo: OrderInformation;
    clientInfo: ClientInformation;

    productOrderDetailsTableColumns = [
        'item', 'dataSet', 'productID', 'symbol',
        'exchange', 'purchaseType', 'engagementPeriod',
        'dateFrom', 'dateTo', 'pricingTier', 'price'
    ];
    public dataSource = new MatTableDataSource<any>();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private currencyService: CurrencyService,
        private orderService: OrderService,
        private salesService: SalesService,
        private downloadInvoiceService: DownloadInvoiceService,
        private swalService: SwalAlertService,
        private breadCrumbService: BreadCrumbService,
        private productService: ProductService,
        private configService: ConfigService,
        private orderAmountBuilder: OrderAmountBuilderService,
        private orderInformationsBuilder: OrderInformationsBuilderService,
        private clientInformationsBuilder: ClientInformationsBuilderService
    ) {
        this.route.params.subscribe(_ => this.idCmd = _.id);
    }

    ngOnInit() {
        this.cart = [];
        this.symbols = [];
        this.reason = '';
        this.action = '';
        this.symbol = '';
        this.editOff = false;
        this.getListStates();
        this.getCmd();
        this.salesService.getSales().subscribe(result => {
            this.listSales = result;
        });

        this.period = [];
        this.getPeriod();


        this.subscribeToMenuActions();

    }
    private subscribeToMenuActions() {
        this.productService.cancelOrderForProduct.subscribe(response => {
            if (response) {
                this.cancelOrder();
            }
        });
        this.productService.cancelValidationForProduct.subscribe(response => {
            if (response) {
                this.cancelValidation();
            }
        });

        this.productService.rejectOrderForProduct.subscribe(response => {
            if (response) {
                this.rejectOrder();
            }
        });

        this.productService.updateChangesForProduct.subscribe(async (response) => {
            if (response) {
                await this.updateOrderByProduct();
                this.editOff = false;
                this.orderService.authoriseToEditDiscount(this.editOff);

            }
        });

        this.productService.validateOrderForProduct.subscribe(response => {
            if (response) {
                this.validateOrder();
            }
        });

        this.productService.editOrderForProduct.subscribe(response => {
            this.editOff = response;
            this.orderService.authoriseToEditDiscount(this.editOff);
        });
    }

    setPeriod(product, event) {
        if (parseInt(event.target.value) <= 0 || !event.target.value) {
            event.target.value = 1
        }
        if (product.historical_data.backfill_applyfee && !product.historical_data.direct_billing) {
            var PeriodsOfProductsWithSameEid = this.cmd.products.filter(item => item.eid == product.eid && item.subscription == 1).map(item => ({ id: item.id_undercmd, period: item.period }))
            var maxPeriodOfEid = Math.max(...PeriodsOfProductsWithSameEid.map(item => item.period));
            PeriodsOfProductsWithSameEid.find(item => item.id == product.id_undercmd).period = parseInt(event.target.value);
            var newMaxperiodOfEid = Math.max(...PeriodsOfProductsWithSameEid.map(item => item.period));
            if (maxPeriodOfEid != newMaxperiodOfEid) {
                this.cmd.totalExchangeFees -= ((maxPeriodOfEid - newMaxperiodOfEid) * product.ongoing_fee)
            }
        }
        product.period = parseInt(event.target.value);
        product.ht = (parseInt(event.target.value) * product.price) * this.cmd.currencyTxUsd
        this.calculateAmounts();
        this.orderAmount = this.orderAmountBuilder.transformCmdToOrederAmount(this.cmd)
    }

    calculateAmounts() {
        this.cmd.dataAmount = this.cmd.products.map(item => item.ht).reduce((a, b) => a + b, 0) * (1 - (this.cmd.discount / 100));
        this.cmd.totalHTDiscountFree = this.cmd.dataAmount + this.cmd.totalExchangeFees;
        this.cmd.totalHT = this.cmd.dataAmount + this.cmd.totalExchangeFees;
        this.cmd.totalVat = this.cmd.vatValue * this.cmd.totalHT;
        this.cmd.total = this.cmd.totalHT + this.cmd.totalVat
    }
    getCmd() {

        this.currencyService.getCurrencies().subscribe(r => {
            this.symbols = [];
            r.currencies.forEach(s => {
                this.symbols[s.id] = s.symbol;
            });

            this.productService.getOrderById(this.idCmd).subscribe((c) => {
                this.cmd = c;
                this.orderAmount = this.orderAmountBuilder.transformCmdToOrederAmount(this.cmd);
                this.orderInfo = this.orderInformationsBuilder.transformCmdToOrderInformations(this.cmd);
                this.clientInfo = this.clientInformationsBuilder.transformCmdToClientOrderInformations(this.cmd);
                if (this.cmd.products.length > 0) {
                    //to send the order when the details page is loded  
                    this.breadCrumbService.getOrderForView(this.cmd);
                    this.existSubscribe = false;
                }
                let index = 1;
                this.cmd.products.forEach(element => {
                    element.item = index++
                });

                this.dataSource.data = this.cmd.products;

            });
        });
    }


    verifStatePVP() {
        let statesForCancel = ['PVP', 'PVF'];
        return statesForCancel.includes(this.cmd.state);
    }
    verifState() {
        return ['PSC'].includes(this.cmd.state) || this.verifStatePVP();
    }
    verifStateForCancel() {
        return ['active', 'validated'].includes(this.cmd.state) || this.verifState();
    }
    getListStates() {
        this.orderService.getListStates({}).subscribe(res => {
            this.states = res['states'];
        });
    }
    async updateOrderByProduct() {
        await this.updatemetadata()
        await this.applyDiscount();
        await this.updateEngagementPeriod();
        this.getSwalToastNotification();

    }
    applyDiscount() {
        return new Promise((resolve, reject) => {
            this.productService.updateDiscount({ orderId: this.cmd._id, discount: this.cmd.discount }).subscribe(res => {
                resolve(res);
            })
        });
    }
    updateEngagementPeriod() {
        return new Promise((resolve, reject) => {
            this.productService.updateEngagementPeriod({ id: this.cmd._id, cart: this.cmd.products }).subscribe(res => {
                resolve(res);
            })
        });

    }
    updatemetadata() {
        return new Promise((resolve, reject) => {
            this.productService.SaveOrderMetadata(this.cmd._id, this.cmd.internalNote, this.cmd.sales, this.cmd.type).subscribe((res) => {
                resolve(res);
            })
        }
        );
    }

    onDiscountChange(newDiscountValue) {
        this.cmd.discount = newDiscountValue;
        if (this.cmd.discount < 0 || this.cmd.discount == null) this.cmd.discount = 0;
        if (this.cmd.discount > 100) this.cmd.discount = 100;
        this.calculateAmounts();
        this.orderAmount = this.orderAmountBuilder.transformCmdToOrederAmount(this.cmd)

        // this.orderService.getNewOrderAmountValues(this.orderAmountBuilder.transformCmdToOrederAmount(this.cmd));
    }

    downloadInvoice(invoice, pdfType) {
        this.downloadInvoiceService.getInvoice(this.cmd._id, invoice, pdfType);
    }

    async validateOrder() {
        if (this.cmd.type === 'NA') {
            this.swalService.getSwalForNotification('Validation Failed !', "Order type is mandatory", 'error')
            return
        }
        let statusAfterValidation = 'PVF';
        var result = await this.swalService.getSwalForConfirm('Are you sure?', `You are going to validate order number <b> ${this.cmd.id}</b>`)
        if (result.value) {
            this.productService.StatusUpdate({ id: this.cmd._id, status: statusAfterValidation, orderType: this.cmd.type })
                .subscribe(result => {
                    if (result) {
                        this.swalService.getSwalForNotification(`Order ${this.cmd.id} validated`, ` <b> Order ${this.cmd.id} validated`);
                        this.router.navigate(['/product/orders'])
                    }
                },
                    error => {
                        this.swalService.getSwalForNotification('Validation Failed !', error.error.message, 'error')
                    });
        }

    }

    async rejectOrder() {
        var result = await this.swalService.getSwalForConfirmWithMessage('Are you sure?', `You are going to reject order number <b> ${this.cmd.id}</b>`)
        if (result.isConfirmed) {
            this.productService.RejectOrder({ idCmd: this.idCmd, id: this.cmd._id, status: 'rejected', referer: 'Product', reason: result.value })
                .subscribe(result => {
                    if (result) {
                        this.swalService.getSwalForNotification(`Order ${this.cmd.id} rejected`, ` <b> Order ${this.cmd.id} rejected`),
                            error => {
                                this.swalService.getSwalForNotification('Rejection Failed !', error.message, 'error')
                            }
                    }
                })
            this.router.navigate(['/product/orders']);
        }
    }

    async cancelOrder() {
        var result = await this.swalService.getSwalForConfirm('Are you sure?', `You are going to cancel order number <b> ${this.cmd.id}</b>`)
        if (result.value) {
            this.productService.CancelOrder({ idCmd: this.idCmd, id: this.cmd._id, status: 'cancelled', referer: 'Product' })
                .subscribe(result => {
                    if (result) {
                        this.swalService.getSwalForNotification(`Order ${this.cmd.id} cancelled`, ` <b> Order ${this.cmd.id} cancelled`),
                            error => {
                                this.swalService.getSwalForNotification('Cancellation Failed !', error.message, 'error')
                            }
                    }
                })
            this.router.navigate(['/product/orders']);
        }
    }

    async cancelValidation() {
        var result = await this.swalService.getSwalForConfirm('Are you sure?', `You are going to cancel order validation number <b> ${this.cmd.id}</b>`)
        if (result.value) {
            this.productService.cancelProductValidation(this.cmd._id)
                .subscribe(result => {
                    if (result) {
                        this.swalService.getSwalForNotification(`Order ${this.cmd.id} validation cancelled`, ` <b> Order ${this.cmd.id} validation cancelled`),
                            error => {
                                this.swalService.getSwalForNotification('Validation Cancellation Failed !', error.message, 'error')
                            }
                    }
                })
            this.router.navigate(['/product/orders']);
        }
    }

    getSwalToastNotification() {
        this.swalService.getSwalToastNotification(false, 1500, true, 'Changes Saved');
    }

    getPeriod() {
        this.configService.getDownloadSetting().subscribe(period => {
            return this.period = period
        })
    }

    getNewSales(sales) {
        this.cmd.sales = sales;
    }

    getNewOrderType(type) {
        this.cmd.type = type
    }


    ngOnDestroy(): void {
        this.breadCrumbService.getOrderForView(null);
        this.productService.orderToCancel.next(null)
        this.productService.orderToCancelValidation.next(null)
        this.productService.orderToReject.next(null)
        this.productService.orderToValidate.next(null)
        this.productService.orderToUpdateChanges.next(null)
        this.orderService.orderAmountToEdit.next(false);
    }
}




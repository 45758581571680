import { Routes } from "@angular/router";
import { GuardGuard } from "../../guard/guard.guard";
import { UsersComponent } from "./users/users.component";
import { RoleComponent } from "./role/role.component";
import { CountriesComponent } from "./countries/countries.component";
import { TermsComponent } from "./terms/terms.component";
import { VariableComponent } from "./variable/variable.component";
import { HelpComponent } from "./help/help.component";
import { AdministratorComponent } from "./administrator/administrator.component";

export const administrationRoutes: Routes = [
    {
        path: "admin",
        component: AdministratorComponent,
        children: [
            { path: '', redirectTo: 'role', canActivate: [GuardGuard], pathMatch: "full" },
            { path: 'users', component: UsersComponent, canActivate: [GuardGuard] },
            { path: 'role', component: RoleComponent, canActivate: [GuardGuard] },
            { path: 'countries', component: CountriesComponent, canActivate: [GuardGuard] },
            { path: 'terms', component: TermsComponent, canActivate: [GuardGuard] },
            { path: 'variable', component: VariableComponent, canActivate: [GuardGuard] },
            { path: 'help', component: HelpComponent, canActivate: [GuardGuard] }
        ],
    }

]

import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {
  NgbTabChangeEvent, NgbDatepickerConfig,
  NgbModal, ModalDismissReasons, NgbDateStruct, NgbCalendar
} from '@ng-bootstrap/ng-bootstrap';

import { environment } from '../../../../environments/environment';
import { DataService } from '../../../services/data.service';
import { ElasticService } from '../../../services/elastic.service';

import { OrderService } from '../../../services/order.service';
import { UserService } from '../../../services/user.service';
import { ConfigService } from '../../../services/config.service';
import { FluxService } from '../../../services/flux.service';
import { MatButton } from '@angular/material/button';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../formatters/format-datepicker';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { DateTimeToStringConverterService } from '../../../converters/date-time-to-string-converter.service'

class Product {
  warn: string = "warn";
  idx: string;

  onetime: number;
  subscription: number;
  quotation_level: string;
  begin_date: string;
  end_date: string;
}

const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})

export class SearchComponent implements OnInit, AfterViewInit {
  caddies: Array<object>;
  tabPrice: any;
  ds: string;
  exchangesRef: any;
  assetsRef: any;
  tabPricingTier: any;
  user: object;
  catalogue: object;
  formatfile: string;
  caddy: Array<object>;
  tabsearch: any;
  to: number;
  from: number;
  pageTo: number;
  total: number;
  btnSearch: boolean;
  onetimeTo: Date;
  onetimeFrom: Date;
  runsearch: boolean;
  dataset: any;
  datasets: Array<object>;
  pagesize: any;
  page: number;
  reqSearch: {
    fields: string[], query: { bool: { must: any[], should: any[], must_not: any[] } }
    , aggs: object, from: number, size: number, index: any[], type: any[]
  };
  nbperpage: string;
  nassc: string;
  exchange: string;
  period: { begin_date: string, end_date: string };
  periodSubscription: number;
  options: Array<any>;
  closeResult: string;
  search: string;
  assets: Array<object>;
  nbPerPage: Array<object>;
  exchanges: Array<object>;
  assetsSearch: Array<object>;
  exchangesSearch: Array<object>;
  out: object;
  hoveredDate: NgbDateStruct;
  fromDate: NgbDateStruct;
  calendar: NgbCalendar;
  toDate: NgbDateStruct;
  minDate: { year: number, month: number, day: number };
  maxDate: { year: number, month: number, day: number };
  hits: Array<any>;
  avaibility: boolean;
  addCart: {
    onetime: number,
    complete: number,
    dateFromRef: string,
    dateToRef: string,
    onetimeFrom: string,
    onetimeTo: string,
    subscription: number,
    products: Array<Product>
  };

  all: string;
  symbol: string;
  isin: string;
  mics: string;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private data: DataService,
    private elasticService: ElasticService,
    private userService: UserService,
    private fluxService: FluxService,
    private configService: ConfigService,
    private orderService: OrderService,
    private modalService: NgbModal,
    private calenda: NgbCalendar,
    private config: NgbDatepickerConfig,
    private dateTimeToStringConverter: DateTimeToStringConverterService
  ) {

    config.minDate = { year: 2000, month: 1, day: 1 };
    config.maxDate = { year: 2050, month: 12, day: 31 };

    config.outsideDays = 'hidden';

    this.avaibility = false;
    this.calendar = calenda;
    this.reset();
    this.options = [];
    this.reqSearch.from = 0;
    this.nbperpage = '10';
    this.btnSearch = true;
    this.total = 0;
    this.addCart = {
      onetime: 0,
      complete: 0,
      dateFromRef: '',
      dateToRef: '',
      onetimeFrom: '',
      onetimeTo: '',
      subscription: 0,
      products: []
    };
  }

  @ViewChild('runsearch', { static: false })
  private inputEl: MatButton;

  ngAfterViewInit() {
    this.inputEl.focus();
  }

  ngOnInit() {
    this.dataset = JSON.parse(sessionStorage.getItem('dataset'));
    if (!this.dataset || !this.dataset.hasOwnProperty('dataset')) {
      this.router.navigateByUrl('/');
    } else {
      this.getCatalogue();
    }
    this.search = this.dataset.title;
    this.exchanges = [];
    this.assets = [];
    this.tabsearch = 'instrument';
    this.formatfile = 'csv';
    this.getShowEntries();
    this.datasets = [
      { id: 'L1TRADEONLY', name: 'L1 - Trades', search: 'Find Times & Sales' },
      { id: 'L1', name: 'L1 - Full', search: 'Find Top of Book' },
      { id: 'L2', name: 'L2', search: 'Find L2-MBL' }
    ];
  }
  resetSelect() {
    this.hits.forEach(hit => {
      hit.selected = false;
    });
    this.addCart = {
      onetime: 0,
      complete: 0,
      dateFromRef: '',
      dateToRef: '',
      onetimeFrom: '',
      onetimeTo: '',
      subscription: 0,
      products: []
    };
  }
  setDataset(e) {
    this.reset();
    const dtst = this.searchDataset(e, this.datasets);
    sessionStorage.setItem('dataset', JSON.stringify({ dataset: dtst.id, title: dtst.name }));
    this.data.changeSearch(dtst.search);
    this.search = dtst.search
    this.getCatalogue();
  }

  getCatalogue() {
    this.ds = '';
    if (this.dataset.dataset === 'L1TRADEONLY') { this.ds = 'Trades'; }
    if (this.dataset.dataset === 'L1') { this.ds = 'L1'; }
    if (this.dataset.dataset === 'L2') { this.ds = 'MBL'; }
    this.fluxService.catalogue(this.ds).subscribe(res => {
      this.catalogue = res;
      this.getAssetExchange();
    });
  }

  rSearch() {
    this.btnSearch = false;
    this.page = 0;
    this.getSearch();
  }
  getAssetExchange() {
    let q = {};
    if (this.tabsearch === 'instrument') {
      q['index'] = environment.elastic.instrument.index;
      q['type'] = environment.elastic.instrument.type;
    }
    if (this.tabsearch === 'feed') {
      q['index'] = environment.elastic.feed.index;
      q['type'] = environment.elastic.feed.type;
    }
    q['fields'] = [];
    q['query'] = {
      "bool": {
        "should": [
          {
            "terms": {
              "EID": this.catalogue['tabEid']
            }
          },
          {
            "terms": {
              "ContractEID": this.catalogue['tabEid']
            }
          }
        ]
      }
    };
    q["aggs"] = {
      "exchanges": {
        "terms": {
          "size": 1000,
          "field": "ExchangeName"
        }
      },
      "exchangesLong": {
        "terms": {
          "size": 1000,
          "script": "doc['ExchangeName'].values + '§' + doc['ExchangeLongName'].values"
        }
      },

      "assets": {
        "terms": {
          "size": 1000,
          "field": "AssetClass"
        }
      }
    };
    q['from'] = 0;
    q['size'] = 1;
    this.elasticService.getSearch(q).subscribe(resp => {
      this.getAsset(resp.aggregations.assets.buckets);
      this.getExchangesBis(resp.aggregations.exchanges.buckets, resp.aggregations.exchangesLong.buckets);
    });
  };

  getAsset(assets) {
    this.assets = [];

    assets.forEach(a => {
      this.assets.push({ id: a.key, name: a.key });
    });
  }

  getExchanges(exchange) {
    this.exchanges = [];
    this.fluxService.getExchanges().subscribe(flux => {
      this.exchangesRef = flux;
      exchange.forEach(a => {
        if (a.key !== '' && this.exchangesRef.find(x => x.id === a.key)) {
          this.exchanges.push({ id: a.key, name: this.exchangesRef.find(x => x.id === a.key).name });
        }
      });
    });
  }

  getExchangesBis(exchange, exchangeLong) {
    this.exchanges = [];
    exchangeLong.forEach((a, i) => {
      let key = a.key.split('§');
      this.exchanges.push({ id: key[0].replace('[', '').replace(']', ''), name: key[1].replace('[', '').replace(']', '') });
    });
    this.exchanges.sort(function (a, b) {
      if (a['name'].toUpperCase() < b['name'].toUpperCase()) {
        return -1;
      }
      if (a['name'].toUpperCase() > b['name'].toUpperCase()) {
        return 1;
      }
      return 0;
    });
  }
  getSearch() {

    if (this.tabsearch === 'instrument') {
      this.reqSearch.index = environment.elastic.instrument.index;
      this.reqSearch.type = environment.elastic.instrument.type;
    }
    if (this.tabsearch === 'feed') {
      this.reqSearch.index = environment.elastic.feed.index;
      this.reqSearch.type = environment.elastic.feed.type;
    }
    let fields = [];
    let must = [];
    this.reqSearch.aggs = {};
    this.reqSearch.query.bool.must = [];
    let should = [];

    fields = [
      'Code',
      'ContractID',
      'EID',
      'ContractEID',
      'AssetClass',
      'ContractEID',
      'Description',
      'ContractDescription',
      'Symbol',
      'ContractSymbol',
      'ExchangeName',
      'ContractExchange',
      'ISIN',
      'ContractISIN',
      'AvailabilityStart',
      'AvailabilityEnd',
      'Availability',
      'ContractAvailability',
      'ContractAssetClass',
      'ADTV',
      'Constituents',
      'MICs',
      'ContractADTV'
    ];

    if (this.all !== '') {
      let alltemp = this.all.trim().split(" ");
      let allreq = '';
      alltemp.forEach(al => {
        allreq += ' +"' + al + '"';
      })
      let shouldall = [];

      if (this.symbol === '') {
        shouldall.push({
          'bool': {
            "should": [
              {
                "query_string": {
                  "default_field": "Symbol",
                  "query": allreq
                }
              },
              {
                "query_string": {
                  "default_field": "ContractSymbol",
                  "query": allreq
                }
              }
            ]
          }
        });
      }
      shouldall.push({
        'bool': {
          "should": [
            {
              "query_string": {
                "default_field": "Description",
                "query": allreq
              }

            },
            {
              "query_string": {
                "default_field": "ContractDescription",
                "query": allreq
              }

            }
          ]
        }
      });
      shouldall.push({
        "query_string": {
          "default_field": "LocalCodeStr",
          "query": allreq
        }

      });
      this.reqSearch.query.bool.must.push({
        'bool': {
          'should': shouldall
        }
      });
    }
    if (this.symbol !== '') {
      this.symbol = this.symbol.trim();
      this.reqSearch.query.bool.must.push({
        'bool': {
          "should": [
            {
              'wildcard': {
                'Symbol': {
                  'value': this.symbol.toUpperCase() + '*'
                }
              }
            },
            {
              'wildcard': {
                'ContractSymbol': {
                  'value': this.symbol.toUpperCase() + '*'
                }
              }
            }
          ]
        }
      });
    }
    if (this.isin !== '') {
      this.isin = this.isin.trim();
      this.reqSearch.query.bool.must.push({
        'wildcard': {
          'ISIN': {
            'value': this.isin.toUpperCase() + '*'
          }
        }
      });
    }
    if (this.mics !== '') {
      this.mics = this.mics.trim();
      this.reqSearch.query.bool.must.push({
        'wildcard': {
          'MICs': {
            'value': this.mics + '*'
          }
        }
      });
    }

    if (this.assetsSearch.length > 0) {
      this.reqSearch.query.bool.must.push({
        'bool': {
          "should": [
            { 'terms': { 'AssetClass': this.assetsSearch } },
            { 'terms': { 'ContractAssetClass': this.assetsSearch } }
          ]
        }
      });
    }
    if (this.exchangesSearch.length > 0) {
      this.reqSearch.query.bool.must.push({
        "bool": {
          "should": [
            { 'terms': { 'ExchangeName': this.exchangesSearch } },
            { 'terms': { 'ContractExchange': this.exchangesSearch } },
          ]
        }
      });
    }
    this.reqSearch.query.bool.must.push({
      "bool": {
        "should": [
          { 'terms': { 'EID': this.catalogue['tabEid'] } },
          {
            "terms": { "ContractEID": this.catalogue['tabEid'] }
          }
        ]
      }
    });

    this.reqSearch.fields = fields;
    this.out = this.reqSearch;
    if (this.all !== '' || this.symbol !== '' || this.isin !== '' || this.mics !== '' || this.exchangesSearch.length > 0 || this.assetsSearch.length > 0) {
      this.elasticService.getSearch(this.reqSearch).subscribe(resp => {

        this.hits = [];
        resp.hits.hits.forEach(hit => {
          hit['selected'] = false;
          this.hits.push(hit);
        });
        this.total = resp.hits.total;
        this.pagesize = resp.hits.total - parseInt(this.nbperpage, 10);
        if (this.pagesize <= 0) {
          this.from = 1;
          this.to = this.total;
        }
      });
    }
  };

  reset() {
    this.nbperpage = '10';
    this.pagesize = 0;
    this.page = 0;
    this.total = 0;
    this.from = 0;
    this.to = 0;
    this.reqSearch = { fields: [], query: { bool: { must: [], should: [], must_not: [] } }, aggs: {}, from: 0, size: parseInt(this.nbperpage, 10), index: [], type: [] };
    this.all = '';
    this.symbol = '';
    this.isin = '';
    this.mics = '';
    this.exchange = '';
    this.nassc = '';
    this.assetsSearch = [];
    this.exchangesSearch = [];
    this.hits = [];
    this.options = [];
    this.addCart = {
      onetime: 0,
      complete: 0,
      dateFromRef: '',
      dateToRef: '',
      onetimeFrom: '',
      onetimeTo: '',
      subscription: 0,
      products: []
    };
    this.avaibility = false;
  };

  addnbperpage(e) {
    this.page = 0;
    this.nbperpage = e;
    this.to = parseInt(this.nbperpage, 10);
    this.reqSearch.size = parseInt(this.nbperpage, 10);
    this.getSearch();
  };

  updPagination() {
    this.pageTo = parseInt(this.nbperpage, 10);
    this.from = ((this.page - 1) * this.pageTo) + 1;
    this.reqSearch.from = (this.page - 1) * this.pageTo;
    if ((this.reqSearch.from + this.pageTo) > this.total) {
      this.to = this.total
    } else {
      this.to = this.page * this.pageTo;
    }
    if (this.btnSearch) {
      this.getSearch();
    } else {
      this.btnSearch = true;
    }
  };

  addAsset(e) {
    this.assetsSearch.push(e);
    this.inputEl.focus();
  };

  addEchange(e) {
    this.exchangesSearch.push(e);
    this.inputEl.focus();
  };
  btnFocus() {
    this.inputEl.focus();
  }
  getShowEntries() {
    this.nbPerPage = [
      { id: '5', name: '5' },
      { id: '10', name: '10' },
      { id: '25', name: '25' },
      { id: '50', name: '50' },
      { id: '100', name: '100' }
    ];
  };

  delEx(e) {
    this.exchangesSearch.splice(this.exchangesSearch.indexOf(e), 1);
    this.inputEl.focus();
  }
  delAs(a) {
    this.assetsSearch.splice(this.assetsSearch.indexOf(a), 1);
    this.inputEl.focus();
  }
  datePeriod() {
    this.addCart.complete = 0;
  }
  dateComplete() {
    this.addCart.complete = 1;
    this.addCart.onetimeFrom = '';
    this.addCart.onetimeTo = '';
    this.addCart.products.forEach((p, i) => {
      this.addCart.products[i].begin_date = this.addCart.products[i].begin_date;
      this.addCart.products[i].end_date = this.addCart.products[i].end_date;
    });
  }
  dateChangeBegin(date) {
    this.addCart.complete = 0;
    if (date) {
      this.addCart.products.forEach((p, i) => {
        let bd = new Date(p.begin_date);
        let ed = new Date(p.end_date);
        if (bd <= date) {
          if (ed >= date) {
            this.addCart.products[i].begin_date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
          } else {
            this.addCart.products[i].begin_date = p.end_date;
          }
        }
      });
    }
  }
  dateChangeEnd(date) {
    this.addCart.complete = 0;
    if (date) {
      this.addCart.products.forEach((p, i) => {
        let ed = new Date(p.end_date);
        let bd = new Date(p.begin_date);
        if (ed >= date) {
          if (bd <= date) {
            this.addCart.products[i].end_date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
          } else {
            this.addCart.products[i].end_date = p.begin_date;
          }
        }
      });
    }
  }

  dateChangeFrom(dateFrom) {
    let convOneTimeFrom = new Date();
    let convOneTimeTo = new Date();
    let convOneTimeFromMin = new Date();

    convOneTimeFrom = this.onetimeFrom;
    convOneTimeFromMin = new Date(this.minDate.year, this.minDate.month - 1, this.minDate.day);
    convOneTimeTo = this.onetimeTo;

    if (convOneTimeFrom > convOneTimeTo) {
      this.onetimeFrom = convOneTimeTo;
    }
    if (convOneTimeFrom < convOneTimeFromMin) {
      this.onetimeFrom = convOneTimeFromMin;
    }
    this.addCart.onetimeFrom = this.onetimeFrom.getFullYear() + '-' + (this.onetimeFrom.getMonth() + 1) + '-' + this.onetimeFrom.getDate();
    this.dateChangeBegin(this.onetimeFrom);
  }

  dateChangeTo(dateTo) {
    let convOneTimeFrom = new Date();
    let convOneTimeTo = new Date();
    let convOneTimeToMax = new Date();

    convOneTimeFrom = this.onetimeFrom;
    convOneTimeTo = this.onetimeTo;
    convOneTimeToMax = new Date(this.maxDate.year, this.maxDate.month - 1, this.maxDate.day);

    if (convOneTimeFrom > convOneTimeTo) {
      this.onetimeTo = convOneTimeFrom;
    }
    if (convOneTimeTo > convOneTimeToMax) {
      this.onetimeTo = convOneTimeToMax;
    }
    this.addCart.onetimeTo = this.onetimeTo.toJSON();
    this.dateChangeEnd(this.onetimeTo);
  }

  updtCheck(selectedProduct, event) {
    if (event.target.checked) {
      let product = new Product();
      let av0 = new Date(selectedProduct._source.AvailabilityStart);
      let av1 = new Date(selectedProduct._source.AvailabilityEnd);
      if (this.addCart.products.length === 0) {
        this.minDate = { year: av0.getFullYear(), month: (av0.getMonth() + 1), day: av0.getDate() };
        this.config.minDate = this.minDate;
        this.maxDate = { year: av1.getFullYear(), month: (av1.getMonth() + 1), day: av1.getDate() };
        this.config.maxDate = this.maxDate;
      } else {
        if (av0 < new Date(this.minDate.year + '-' + this.minDate.month + '-' + this.minDate.day)) {
          this.minDate = { year: av0.getFullYear(), month: (av0.getMonth() + 1), day: av0.getDate() };
          this.config.minDate = this.minDate;
        }
        if (av1 > new Date(this.maxDate.year + '-' + this.maxDate.month + '-' + this.maxDate.day)) {
          this.maxDate = { year: av1.getFullYear(), month: (av1.getMonth() + 1), day: av1.getDate() };
          this.config.maxDate = this.maxDate;
        }
      }
      product.idx = selectedProduct._id;
      product.quotation_level = this.dataset.dataset;
      product.begin_date = av0.toJSON();
      product.end_date = av1.toJSON();
      this.addCart.products.push(product);
    } else {
      this.addCart.products.splice(this.addCart.products.indexOf(selectedProduct), 2);
    }
  }
  getAvailability(db, ed) {
    this.avaibility = true;
    let dd = new Date(db);
    let df = new Date(ed);
    let toFro = this.calendar.getToday();
    toFro.day = dd.getDate();
    toFro.month = dd.getMonth() + 1;
    toFro.year = dd.getFullYear();
    let toDat = this.calendar.getToday();
    toDat.day = df.getDate();
    toDat.month = df.getMonth() + 1;
    toDat.year = df.getFullYear();
    this.fromDate = this.calendar.getPrev(toFro, 'd', 0);
    this.toDate = this.calendar.getNext(toDat, 'd', 0);
  }

  close() {
    this.avaibility = false;
  }

  product(prd, onetime, subscription) {
    return {
      idx: prd.idx,
      quotation_level: prd.quotation_level,
      begin_date: onetime ? prd.begin_date : this.addCart.dateFromRef,
      end_date: onetime ? prd.end_date : this.addCart.dateToRef,
      onetime: onetime,
      subscription: subscription,
    };
  }

  save() {
    let caddy = [];
    if (this.addCart.onetime === 1) {
      this.addCart.products.forEach((prd) => {
        var prod = this.product(prd, this.addCart.onetime, 0);
        prod.begin_date = this.dateTimeToStringConverter.convertDateToString(new Date(prd.begin_date));
        prod.end_date = this.dateTimeToStringConverter.convertDateToString(new Date(prd.end_date));
        caddy.push(prod);
      });
    }
    if (this.addCart.subscription === 1) {
      this.addCart.products.forEach((prd) => {
        var prod = this.product(prd, 0, this.addCart.subscription);
        prod.begin_date = this.dateTimeToStringConverter.convertDateToString(new Date(prd.begin_date));
        prod.end_date = this.dateTimeToStringConverter.convertDateToString(new Date(prd.end_date));
        caddy.push(prod);
      })
    }
    this.orderService.addToCart({ cart: caddy }).subscribe((res) => {
      this.resetSelect();
    });
  }


  addCaddies() {
    let c = [];
    if (c = JSON.parse(sessionStorage.getItem('cart'))) {
      this.addCart.products.forEach((prd) => {
        prd.onetime = this.addCart.onetime;
        prd.subscription = this.addCart.subscription;
        c.push(prd);
      });
      sessionStorage.setItem('cart', JSON.stringify(c));
    } else {
      c = [];
      this.addCart.products.forEach((prd) => {
        prd.onetime = this.addCart.onetime;
        prd.subscription = this.addCart.subscription;
        c.push(prd);
      });
      sessionStorage.setItem('cart', JSON.stringify(c));
    }
  }

  isHovered = date => this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate);
  isInside = date => after(date, this.fromDate) && before(date, this.toDate);
  isFrom = date => equals(date, this.fromDate);
  isTo = date => equals(date, this.toDate);

  onDateChange(date: NgbDateStruct) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  open(content) {
    this.addCart.onetimeFrom = this.minDate.year + '-' + this.minDate.month + '-' + this.minDate.day;
    this.addCart.onetimeTo = this.maxDate.year + '-' + this.maxDate.month + '-' + this.maxDate.day;
    this.addCart.dateFromRef = this.minDate.year + '-' + this.minDate.month + '-' + this.minDate.day;
    this.addCart.dateToRef = this.maxDate.year + '-' + this.maxDate.month + '-' + this.maxDate.day;
    this.onetimeFrom = new Date(this.minDate.year, (this.minDate.month - 1), this.minDate.day);
    this.onetimeTo = new Date(this.maxDate.year, (this.maxDate.month - 1), this.maxDate.day);
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  public beforeChange($event: NgbTabChangeEvent) {
    this.tabsearch = $event.nextId;
    this.getAssetExchange();
  };

  onetimeCheckbox(element: HTMLInputElement): void {
    if (element.checked) {
      this.addCart.onetime = 1;
    } else {
      this.addCart.onetime = 0;
    }
  }

  subscriptionCheckbox(element: HTMLInputElement): void {
    if (element.checked) {
      this.addCart.subscription = 1;

    } else {
      this.addCart.subscription = 0;
    }
  }
  isString(val) { return typeof val === 'string'; }
  isNumber(val) { return typeof val === 'number'; }

  dateDiff(date1, date2) {
    let diff = { sec: 0, min: 0, hour: 0, day: 0 };
    let tmp = date2 - date1;
    tmp = Math.floor(tmp / 1000);
    diff.sec = tmp % 60;
    tmp = Math.floor((tmp - diff.sec) / 60);
    diff.min = tmp % 60;
    tmp = Math.floor((tmp - diff.min) / 60);
    diff.hour = tmp % 24;
    tmp = Math.floor((tmp - diff.hour) / 24);
    diff.day = tmp;
    return diff;
  }

  dateNGB(d) {
    let dm = this.calenda.getToday();
    let dsplit = d.split('-');
    dm.year = parseInt(dsplit[0]);
    dm.month = parseInt(dsplit[1]);
    dm.day = parseInt(dsplit[2]);
    return dm;
  }

  objectToArray(a) {
    let t = [];
    for (let key in a) {
      t.push(a[key]);
    }
    return t;
  }

  searchDataset(nameKey, myArray) {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].id === nameKey) {
        return myArray[i];
      }
    }
  }
  searchExchangeName(nameKey, myArray) {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].desc === nameKey) {
        return myArray[i];
      }
    }
  }
  objectToArrayAss(a) {
    let t = [];
    a.forEach(element => {
      for (let key in element) {
        t[key] = a[key];
      }
    });
  }
}

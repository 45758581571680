import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfigService } from '../../services/config.service';
import { environment } from '../../../environments/environment';
import { DateTimeToStringConverterService } from '../../converters/date-time-to-string-converter.service';

@Component({
  selector: 'app-links-view',
  templateUrl: './links-view.component.html',
  styleUrls: ['./links-view.component.css']
})
export class LinksViewComponent implements OnInit {
  activeLinks: any[] = [];
  expiredLinks: any[] = [];
  linkStatus: string;
  gateway: string;
  path: string;
  today: Date;
  linkDateCreation: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dateTimeToStringConverter: DateTimeToStringConverterService) { }
  displayedColumns: string[] = ['date', 'type', 'expirationDate', 'remainingDays', 'link'];
  activeLinksDataSource: MatTableDataSource<any>;
  expiredLinksDataSource: MatTableDataSource<any>;
  @ViewChild('activeLinksPaginator', { read: MatPaginator }) activeLinksPaginator: MatPaginator;
  @ViewChild('expiredLinksPaginator', { read: MatPaginator }) expiredLinksPaginator: MatPaginator;
  @ViewChild('activeLinkSort', { read: MatSort }) activeLinkSort: MatSort;
  @ViewChild('expiredLinkSort', { read: MatSort }) expiredLinkSort: MatSort;


  ngOnInit(): void {
    this.gateway = environment.gateway;
    this.today = new Date();

    this.setLinks();
    this.activeLinksDataSource = new MatTableDataSource(this.activeLinks);
    this.expiredLinksDataSource = new MatTableDataSource(this.expiredLinks);

  }

  private setLinks() {
    this.data.links.forEach(objectLink => {
      this.path = objectLink.path;
      this.linkStatus = objectLink.status;
      this.linkDateCreation = objectLink.createLinkDate;
      objectLink.links.forEach(link => {
        link.link.split('|').forEach(link => {

          let type = link.indexOf("L1") !== -1 ? "L1" : link.indexOf("Trades") !== -1 ? "L1-Trades" : link.indexOf("L2") !== -1 ? "L2" : link.indexOf("Referential") !== -1 ? "Referential" : "Ticksizes";
          let expirationDate = this.limitDownLoad(this.linkDateCreation);
          let fullLink = this.gateway + "/api/v1/user/download/" + this.data.userToken + "/" + this.path + "/" + link;
          let dateDiffDay = this.dateDiff(this.today, expirationDate).day;

          if (dateDiffDay > 0 && this.linkStatus === "active") {
            this.activeLinks.push({
              date: link.slice(0, link.indexOf("_")), type: type, expirationDate: this.dateTimeToStringConverter.convertDateToString(new Date(expirationDate)), remainingDays: dateDiffDay + 1, link: fullLink
            });
          }
          else {
            this.expiredLinks.push({
              date: link.slice(0, link.indexOf("_")), type: type, expirationDate: this.dateTimeToStringConverter.convertDateToString(new Date(expirationDate)), remainingDays: 0, link: link
            });
          }
        });
      });
    });
  }

  ngAfterViewInit() {
    this.activeLinksDataSource.paginator = this.activeLinksPaginator;
    this.expiredLinksDataSource.paginator = this.expiredLinksPaginator;
    this.activeLinksDataSource.sort = this.activeLinkSort;
    this.expiredLinksDataSource.sort = this.expiredLinkSort;
  }



  dateDiff(date1, date2) {
    let diff = { sec: 0, min: 0, hour: 0, day: 0 };
    let tmp = date2 - date1;
    tmp = Math.floor(tmp / 1000);
    diff.sec = tmp % 60;
    tmp = Math.floor((tmp - diff.sec) / 60);
    diff.min = tmp % 60;
    tmp = Math.floor((tmp - diff.min) / 60);
    diff.hour = tmp % 24;
    tmp = Math.floor((tmp - diff.hour) / 24);
    diff.day = tmp;
    return diff;
  }

  limitDownLoad(linkDate) {
    let expirationDate = new Date(linkDate);
    return expirationDate.setDate(expirationDate.getDate() + this.data.period[0].periodOneOff);
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OrderInformation } from '../models/order-information.model';
import { DownloadInvoiceService } from '../../../services/Intern/download-invoice.service';
import { ProductService } from '../../../services/product.service';

@Component({
  selector: 'app-order-information',
  templateUrl: './order-information.component.html',
  styleUrls: ['./order-information.component.css']
})
export class OrderInformationComponent implements OnInit {

  @Input() orderInfo: OrderInformation;
  @Input() isCompliance: OrderInformation;
  @Input() listSales: string[] = [];
  @Output() getNewSales = new EventEmitter<string>(null);
  @Output() getNewOrderType = new EventEmitter<string>(null);
  editOff: boolean = false;
  constructor(private downloadInvoiceService: DownloadInvoiceService, private productService: ProductService) { }

  ngOnInit() {
    this.productService.editOrderForProduct.subscribe(response => {
      this.editOff = response
    });
  }

  downloadInvoice(invoice, pdfType) {
    this.downloadInvoiceService.getInvoice(this.orderInfo._id, invoice, pdfType);
  }

  getSales() {
    this.getNewSales.emit(this.orderInfo.sales);
  }

  getOrderType() {
    this.getNewOrderType.emit(this.orderInfo.type);
  }


}

import { Routes } from "@angular/router";
import { GuardGuard } from "../../guard/guard.guard";
import { OrdersViewComponent } from "./orders-view/orders-view.component";
import { ConfigComponent } from "./config/config.component";
import { OrdersComponent } from "./orders/orders.component";
import { FinanceComponent } from "./finance/finance.component";

export const financeRoutes: Routes = [
  {
    path: 'finance', component: FinanceComponent, children: [
      { path: 'config', component: ConfigComponent, canActivate: [GuardGuard] },
      { path: 'orders', component: OrdersComponent, canActivate: [GuardGuard] },
      { path: 'orderview/:id', component: OrdersViewComponent, canActivate: [GuardGuard] }
    ]
  }

]
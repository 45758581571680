import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu/menu.component';
import { RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { HelpPageComponent } from './help-page/help-page.component';
import { HomeComponent } from './home/home.component';
import { MaterialModule } from '../material/material.module';
import { LoaderComponent } from './loader/loader.component';
import { NgxUiLoaderModule,NgxUiLoaderRouterModule,  NgxUiLoaderHttpModule  } from 'ngx-ui-loader';
import { BreadcrumbForListComponent } from './breadcrumb-for-list/breadcrumb-for-list.component';



@NgModule({
  declarations: [
    MenuComponent,
    AboutComponent,
    HelpPageComponent,
    HomeComponent,
    LoaderComponent,
    BreadcrumbForListComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    NgxUiLoaderModule,
    NgxUiLoaderRouterModule,
    NgxUiLoaderHttpModule 
  ],
  exports:[
    MenuComponent,
    AboutComponent,
    HelpPageComponent,
    HomeComponent,
    LoaderComponent,
    BreadcrumbForListComponent
  ]
})
export class TemplatesModule { }

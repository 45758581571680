import { Injectable } from '@angular/core';
import { OrderAmount } from '../../modules/order/models/order-amount.model';

@Injectable({
  providedIn: 'root'
})
export class OrderAmountBuilderService {

  constructor() { }


  transformCmdToOrederAmount(cmd) {
    var orderAmount = <OrderAmount>{}

    orderAmount.currency = cmd.currency;
    orderAmount.dataAmount = cmd.dataAmount;
    orderAmount.discount = cmd.discount ? cmd.discount : 0;
    orderAmount.totalExchangeFees = cmd.totalExchangeFees
    orderAmount.totalHT = cmd.totalHT
    orderAmount.totalTTC = cmd.total
    orderAmount.totalVat = cmd.totalVat
    orderAmount.vatValue = cmd.vatValue

    return orderAmount;
  }

  


  

}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DecodeDataService {

  constructor() { }

  decodeData(data:string){
    return atob(data);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { UserService } from '../../../services/user.service';
import { AuthentificationService } from '../../../services/authentification.service';
import { EncodeDataService } from '../../../services/encode-data.service';
import { SwalAlertService } from '../../../shared/swal-alert/swal-alert.service';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    termspdf: string;
    viewterms: boolean;
    ula: boolean;
    colorMessage: string;
    email: string;
    token: string;
    pwd: string;
    password: string;
    page: string;
    message: string;
    showAll: boolean = false;
    enableMessage: boolean = false;

    constructor(
        private router: Router,
        private swalService: SwalAlertService,
        private userService: UserService,
        private authService: AuthentificationService,
        private activatedRoute: ActivatedRoute,
        private encodeService: EncodeDataService
    ) { }

    ngOnInit() {

        this.termspdf = '/files/historical_data_tc.pdf';
        this.viewterms = false;
        this.ula = false;
        const ula = localStorage.getItem('ula');
        if (ula !== null && ula !== '') {
            this.ula = (ula.toString() === 'true');
        }

        const user = JSON.parse(sessionStorage.getItem('user'));
        if (user !== null && Object.keys(user).length > 0) {
            this.router.navigate(['/home']);
        }
        this.message = '';

        const register = sessionStorage.getItem('register');
        if (register === 'ok') {
            this.message = 'Your account has been created';
            this.colorMessage = 'alert alert-info'
        }
        const url = this.router.url.split('/');

        if (url[1] === "activation") {
            this.activate();
        }

        this.email = '';
        this.token = '';
        this.pwd = '';
        this.password = '';
        const route = this.router.url.split('/');
        this.page = route[1].split("?")[0];
        if (route[1] === 'mdp') {
            this.token = route[2];
        }
    }

    activate() {
        this.activatedRoute.params.subscribe(params => {
            this.userService.activation(params.token).subscribe(res => {
                this.message = res.message;
                this.colorMessage = 'alert alert-info';
                if (this.message === 'User Not Found') {
                    this.colorMessage = 'alert alert-danger';
                } else {
                    this.page = 'activation';
                }
            },
                error => {
                    this.sendAccountActivationMail(params.token)
                });
        });
    }

    mdp() {
        this.userService.verifmail({ email: this.email }).subscribe(res => {
            if (!res.valid) {
                this.colorMessage = 'alert alert-danger';
                this.message = res.message;
            } else {
                this.userService.requestForResetPassword(this.email).subscribe(result => {
                    if (result.mail) {
                        this.colorMessage = 'alert alert-info';
                        this.message = 'An email has just been sent';
                    } else {
                        this.colorMessage = 'alert alert-danger';
                        this.message = 'An error has occurred. Please try again';
                    }
                });
            }
        });
    }

    savemdp() {
        this.userService.mdpmodif({ token: this.token, newPassword: this.password }).subscribe(res => {
            this.colorMessage = 'alert alert-info';
            this.message = 'Password successfully changed';
            setTimeout(() => {
                this.message = '';
                this.router.navigate(['/login']);
            }, 3000);
        });
    }

    check() {
        let redirectTo = this.activatedRoute.snapshot.queryParams["redirectURL"];
        var encrypted = this.encodeService.encodeData(this.password);
        this.authService.login(this.email, encrypted).subscribe(res => {
            redirectTo ? this.router.navigate([redirectTo]) : this.router.navigate(['/home']);
        }
            , error => {
                var errorMessage = "Invalid Password or User Not Found";
                const swalTitle = "Error Authentication !";
                this.swalService.getSwalForConfirm(swalTitle, errorMessage, 'error', false);
            });
    }
    termsOpen() {
        this.viewterms = true;
    }
    termsClose() {
        this.viewterms = false;
    }

    sendAccountActivationMail(token) {
        this.swalService.getSwalForConfirm("Activate your account", "Activate your account", "info").then(result => {
            if (result.isConfirmed) {
                this.userService.regenrateTokenForAccountActivation(token).subscribe(res => {
                    this.swalService.getSwalForConfirm("Activate your account", "A new mail has been sent, please check your inbox to activate your account", "success")
                });
            }
        });
    }

}

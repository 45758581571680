import { Component, OnInit } from '@angular/core';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import { OrderService } from '../../../services/order.service';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-export-orders',
  templateUrl: './export-orders.component.html',
  styleUrls: ['./export-orders.component.css']
})
export class ExportOrdersComponent implements OnInit {
  beginID: any;
  endID: any;
  beginDate: any;
  endDate: any;
  typeexport: string;
  constructor(private orderService: OrderService) { }

  ngOnInit(): void {
    this.beginID = null;
    this.endID = null;
    this.beginDate = null;
    this.endDate = null;
    this.typeexport = "csv";
  }

  exportFile() {
    let req = { state: { $in: ["PVF", "validated"] } };
    // if( this.beginID !== "" && this.endID !== "" ){
    //   req["id"] = { $gte: this.beginID, $lte: this.endID };
    // }
    let ids = {};
    if (this.beginID) {
      ids["$gte"] = this.beginID;
    }
    if (this.endID) {
      ids["$lte"] = this.endID;
    }
    if (this.beginID || this.endID) { req["id"] = ids; }

    let dates = {};
    if (this.beginDate) {
      dates["$gte"] = new Date(this.beginDate.getFullYear() + '-' + (this.beginDate.getMonth() + 1 ) + '-' + this.beginDate.getDate());
    }
    if (this.endDate) {
      dates["$lte"] = new Date(this.endDate.getFullYear() + '-' + (this.endDate.getMonth() + 1) + '-' + this.endDate.getDate());
    }
    if (this.endDate || this.beginDate) { req["submissionDate"] = dates; }

    if (this.typeexport === "csv") {
      this.orderService.getListExport(req).subscribe(data => {
        let options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalseparator: '.',
          showTitle: true,
          useBom: true,
          headers: [
            "Invoice_ID",
            "Client_ID",
            "Client_Name",
            "Client_Country",
            "Order_Date",
            "Order_Type",
            "Payment_Date",
            "Order_Currency",
            "Order_Amount_Before_Taxes",
            "Exchange_Fees",
            "VAT",
            "Payment_Method",
            "Payment_Reference",
            "TOTAL_Order_Amount",
            "Internal_Note",
            "Sales"
          ]
        };
        new AngularCsv(data, 'Invoices_export_' + new Date().getTime(), options);
      });
    }
    if (this.typeexport === "xlsx") {
      this.orderService.getListExport(req).subscribe(data => {
        this.exportAsExcelFiles(data, "Invoices");
      });
    }
  }

  exportAsExcelFiles(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    XLSX.writeFile(workbook, this.toExportFileName(excelFileName));
  }


  toExportFileName(excelFileName: string): string {
    return `${excelFileName}_export_${new Date().getTime()}.xlsx`;
  }

}

import { Component, OnInit } from '@angular/core';
import { FinanceService } from '../../../services/finance.service';
import { BreadCrumbService } from '../../../services/bread-crumb.service';

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.css']
})
export class FinanceComponent implements OnInit {
  splitedroute: string[];
  currentComponent: string;
  id: string;
  order: any;
  constructor(private financeService: FinanceService, private breadCrumbService: BreadCrumbService) { }

  ngOnInit(): void {
    this.breadCrumbService.orderForBreadCrumb.subscribe(order => {
      if (!order) {
        this.id = "";
        return;
      }
      this.id = order.id;
      this.order = order
    });
  }

  validateOrderToFinance(response) {
    if (response) {
      this.financeService.orderToValidateByFinance();
    }
  }
}

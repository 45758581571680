import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { SwalAlertService } from '../../../shared/swal-alert/swal-alert.service';
import { BreadCrumbService } from '../../../services/bread-crumb.service';
import { FinanceService } from '../../../services/finance.service';

@Component({
    selector: 'app-orders-view',
    templateUrl: './orders-view.component.html',
    styleUrls: ['./orders-view.component.css']
})
export class OrdersViewComponent implements OnInit, OnDestroy {
    existSubscribe: boolean;
    message: string;
    cmd: any;
    invoiceId: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private swalService: SwalAlertService,
        private breadCrumbService: BreadCrumbService,
        private financeService: FinanceService
    ) {
        route.params.subscribe(_ => this.invoiceId = _.id);
    }
    ngOnDestroy(): void {
        this.breadCrumbService.getOrderForView(null);
        this.financeService.orderToValidate.next(null);
    }

    ngOnInit() {
        this.message = '';
        this.getCmd();

        this.financeService.orderToValidateForFinance.subscribe(result => {
            if (result) {
                this.validateOrder();
            }
        });
    }

    getCmd() {

        this.financeService.getInvoiceById(this.invoiceId).subscribe((c) => {
            this.cmd = c;
            if (this.cmd.products.length > 0) {
                //to send the functional id when the details page is reloded  
                this.breadCrumbService.getOrderForView(this.cmd);
                this.existSubscribe = false;
            }
            let index = 1
            this.cmd.products.forEach(item => {
                item.idx = index++
            })
        });
    }

    async validateOrder() {
        
        const result = await this.swalService.getSwalForConfirm('Are you sure?', `You are going to validate order number <b> ${this.cmd.id}</b>`)
        if (result.value) {
            this.financeService.financeStatusUpdate({ status: 'validated', id: this.cmd._id })
                .subscribe(result => {
                    if (result) {
                        this.swalService.getSwalForNotification(`Order ${this.cmd.id} validated`, ` <b> Order ${this.cmd.id} validated`),
                            error => {
                                this.swalService.getSwalForNotification('Validation Failed !', error.message, 'error')
                            }
                    }
                })
            this.router.navigate(['/finance/orders']);
        }
    }
}

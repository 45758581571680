import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CeilPipe } from './ceil.pipe';
import { SafeHtmlPipePipe } from './safe-html-pipe.pipe';
import { CallbackPipe } from './callback.pipe';
import { OrderStatePipe } from './order-state.pipe';
import { DataSetPipe } from './data-set.pipe';



@NgModule({
  declarations: [
    CeilPipe,
    DataSetPipe,
    SafeHtmlPipePipe,
    CallbackPipe,
    OrderStatePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CeilPipe,
    DataSetPipe,
    SafeHtmlPipePipe,
    CallbackPipe,
    OrderStatePipe,
  ]
})
export class PipesModule { }

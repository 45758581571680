import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdministratorServiceService {

  constructor(private http: HttpClient) { }

  getUserById(id) { return this.http.get<any>(environment.api + '/administrator/user/' + id) }
  getUsers() { return this.http.get<any>(environment.api + '/administrator/user')}
  updateUser(user) { return this.http.put<any>(environment.api + '/administrator/user', { user: user }) }
  regenrateTokenForAccountActivation(id){return this.http.post<any>(environment.api + '/administrator/user/newTokenForAccountActivation',{id : id})  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadCrumbService {

  constructor() { }

  private order = new BehaviorSubject<any>(null);
  private generateInvoice = new BehaviorSubject<any>(null);
  orderForBreadCrumb = this.order.asObservable();
  toGenerateInvoice = this.generateInvoice.asObservable();

  getOrderForView(order) {
    this.order.next(order);
  }
}

import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { OrderAmount } from '../../../modules/order/models/order-amount.model';
import { OrderInformation } from '../../../modules/order/models/order-information.model';
import { Product } from '../../../core/models/product.model';
import { ClientInformation } from '../../../modules/client/models/client-information.model';
import { CurrencyService } from '../../../services/currency.service';
import { OrderService } from '../../../services/order.service';
import { MatTableDataSource } from '@angular/material/table';
import { ConfigService } from '../../../services/config.service';
import { environment } from '../../../../environments/environment';
import { SupportService } from '../../../services/support.service';
import { DeliverablesService } from '../../../services/deliverables.service';
import { DecodeDataService } from '../../../services/decode-data.service';
import { MatDialog } from '@angular/material/dialog';
import { LinksViewComponent } from '../../links-view/links-view.component';
import { ListMicsComponent } from '../../list-mics/list-mics.component';


@Component({
  selector: 'app-client-view',
  templateUrl: './client-view.component.html',
  styleUrls: ['./client-view.component.css']
})
export class ClientViewComponent implements OnInit, AfterViewInit {

  clientInfo: ClientInformation;
  orderAmount: OrderAmount;
  orderInfo: OrderInformation;
  @Input() order: any;
  @Input() isSupport: boolean = false;
  @Input() isSupportView: boolean = false;
  @Input() isCompliance: boolean = false;
  @Input() isFinance: boolean = false;
  @Input() isClient: boolean = false;
  @Output() openLogs = new EventEmitter<any>();
  @Output() openRetryDeltaDialog = new EventEmitter<any>();
  symbol: string
  token: any;
  gateway: string;
  today: Date;
  vat: number;
  totalTTC: any;
  totalExchangeFees: any;
  totalHT: any;
  discount: any;
  totalVat: number;
  datasets: object;
  details: Array<any>;
  period: any;
  clientOrderDetailsTableColumns: string[] = ['item', 'dataSet', 'instrumentID', 'productID', 'symbol', 'description', 'assetClass', 'exchange', 'mic', 'purchaseType'
    , 'engagementPeriod', 'dateFrom', 'dateTo', 'pricingTier', 'price', 'status', 'exchangeFees', 'expirationDate', 'remainingDays', 'delivrables'];
  public dataSource = new MatTableDataSource<any>();
  print: boolean;
  onetime: number;
  subscription: number;
  path: string;
  link: string;
  userId: string;
  isCartFull: boolean = false;
  hasLinks: boolean = false;
  setting = { element: { dynamicDownload: null as HTMLElement } }
  latestDay: any;


  constructor(private orderService: OrderService,
    private currencyService: CurrencyService,
    private configService: ConfigService,
    private supportService: SupportService,
    private deliverablesService: DeliverablesService,
    private decodeService: DecodeDataService,
    public linksDialog: MatDialog,
    public micsDialog: MatDialog
  ) { }
  ngAfterViewInit(): void {
    this.getDisplayedColumns();
  }
  ngOnInit(): void {
    this.period = [];
    this.getPeriod();
    this.getClientOrderDetails(this.order);
    this.verifyOrderInCart();

    this.gateway = environment.gateway;
    this.today = new Date();

    this.details = [];
    this.datasets = {
      L1: 'L1-Full',
      L1TRADEONLY: 'L1-Trades',
      L2: 'L2'
    };
  }


  getClientOrderDetails(order: any) {
    this.clientInfo = <ClientInformation>{}
    this.orderInfo = <OrderInformation>{}
    this.orderAmount = <OrderAmount>{};
    this.setOrderDetails(order);

  }


  public setOrderDetails(order: any) {
    this.orderInfo.id = order.id;
    this.orderInfo._id = order._id;
    this.orderInfo.submissionDate = order.submissionDate;
    this.orderInfo.payment = order.payment;
    this.orderInfo.invoice = order.idCommande;
    this.orderInfo.proForma = order.idProForma;
    this.orderInfo.state = order.state;
    this.orderInfo.sales = order.sales;
    this.orderInfo.type = order.type;
    this.clientInfo.company = order.companyName;
    this.clientInfo.firstName = order.firstname;
    this.clientInfo.lastName = order.lastname;
    this.clientInfo.job = order.job;
    this.clientInfo.countryBilling = order.countryBilling;
    this.orderAmount.currency = order.currency;
    this.vat = order.vatValue;
    this.totalExchangeFees = order.totalExchangeFees
    this.discount = order.discount;
    this.totalHT = order.totalHT
    this.totalVat = this.totalHT * this.vat;
    this.totalTTC = order.total
    this.userId = order.idUser;
    let index = 0;
    this.details = [];
    if (order.products.length > 0) {
      order.products.forEach(product => {
        index++;
        this.print = product.print;
        let links = [];
        if (!product.links) {
          product['links'] = [];
        }
        product.links.forEach(link => {
          if (link.links.length > 0) {
            this.hasLinks = true
          }
          this.onetime = product.onetime;
          this.subscription = product.subscription;
          links.push(link);
        });
        
        let newProduct = new Product(index, product.dataset, product.qhid, product.eid, product.symbol, product.description, product.assetClass, product.exchangeName, product.mics ? product.mics.filter(item => item != null && item != "") : null, product.subscription, product.period, product.begin_date, product.end_date, product.pricingTier, product.ht, product.backfill_fee, product.ongoing_fee, product.links, product.historical_data, product.id_undercmd, product.idx, product.status);
        this.details.push(newProduct);
      });
    }
    this.dataSource.data = this.details;
    this.orderAmount.dataAmount = order.dataAmount ? order.dataAmount : this.totalHT - this.totalExchangeFees;
    this.orderAmount.currency = order.currency;
    this.orderAmount.totalExchangeFees = this.totalExchangeFees;
    this.orderAmount.vatValue = this.vat;
    this.orderAmount.discount = this.discount;
    this.orderAmount.totalHT = this.totalHT;
    this.orderAmount.totalTTC = this.totalTTC;
    this.orderAmount.totalVat = this.totalVat;
    this.token = this.decodeService.decodeData(order.userToken);
    this.listCurrencies();
  }
  getLatestProductExirationDate(links) {
    let latestDate = [];
    links.forEach(link => {
      latestDate.push(this.limitDownLoad(link.createLinkDate));
    })
    return this.latestDay = latestDate.sort((date1, date2) => date2 - date1)[0]
  }

  listCurrencies() {
    this.currencyService.getCurrencies().subscribe(list => { this.symbol = list.currencies.find(item => item.id == this.orderAmount.currency).symbol });
  }
  dateDiff(date1, date2) {
    let diff = { sec: 0, min: 0, hour: 0, day: 0 };
    let tmp = date2 - date1;
    tmp = Math.floor(tmp / 1000);
    diff.sec = tmp % 60;
    tmp = Math.floor((tmp - diff.sec) / 60);
    diff.min = tmp % 60;
    tmp = Math.floor((tmp - diff.min) / 60);
    diff.hour = tmp % 24;
    tmp = Math.floor((tmp - diff.hour) / 24);
    diff.day = tmp;
    return diff;
  }


  limitDownLoad(linkDate) {
    let expirationDate = new Date(linkDate);
    if (this.onetime === 1 || this.subscription === 1) return expirationDate.setDate(expirationDate.getDate() + this.period[0].periodOneOff);
  }

  getPeriod() {
    this.configService.getDownloadSetting().subscribe(period => {
      return this.period = period
    })
  }


  dynamicDownloadByHtmlTag(product) {
    let fileName = "";
    fileName += this.orderInfo.id;
    fileName += "_" + this.datasets[product.dataSet];
    fileName += "_" + product.productID;
    if (product.symbol !== undefined) fileName += "_" + product.symbol;
    if (product.assetClass !== undefined) fileName += "_" + product.assetClass;
    fileName += "_" + "One-Off";
    fileName += "_" + this.yyyymmdd(product.dateFrom);
    fileName += "_" + this.yyyymmdd(product.dateTo);

    if (this.isSupport) {
      this.supportService.getLink(this.orderInfo.id, (product.item - 1)).subscribe(res => {
        this.createFileToDownload(res.links, fileName);
      });
    }
    else {
      this.deliverablesService.getLink(this.orderInfo.id, (product.item - 1)).subscribe(res => {
        this.createFileToDownload(res.links, fileName);
      })
    }

  }

  yyyymmdd(date) {
    let splittedDate = date.split('-');
    let month = parseInt(splittedDate[1]);
    let day = parseInt(splittedDate[2]);

    return [
      splittedDate[0],
      (month > 9 ? '-' : '-0') + month,
      (day > 9 ? '-' : '-0') + day
    ].join('');
  };

  countLink(links) {
    let linkCounter = 0;
    links.forEach(element => { linkCounter += element.link.split("|").length });
    return linkCounter;
  }
  verifyOrderInCart() {
    return new Promise((resolve, reject) => {
      this.orderService.getCaddy().subscribe(caddy => {
        if (caddy) this.isCartFull = true;
        resolve(caddy)
      }, error => { reject(error); })
    })
  }

  downloadLinks(response) {
    if (response) {
      let fileName = this.orderInfo.id + "_Manifest";
      if (this.isSupport) {
        this.supportService.getLinks(this.orderInfo.id).subscribe(productslinks => {
          this.createFileToDownload(productslinks.links, fileName);
        })
      }
      else {
        this.deliverablesService.getLinks(this.orderInfo.id).subscribe(res => {
          this.createFileToDownload(res.links, fileName);
        })
      }

    }
  }
  createFileToDownload(links, fileName) {
    let downloadablelinks = [];
    if (!this.setting.element.dynamicDownload) this.setting.element.dynamicDownload = document.createElement('a');
    links.forEach(links => {
      links.forEach(link => {
        downloadablelinks.push(link);
      });
    });
    const downloadeLinksString = downloadablelinks.join('\n');
    var textFileAsBlob = new Blob([downloadeLinksString], { type: 'text/plain', endings: 'native' });
    var downloadLink = document.createElement("a");
    downloadLink.download = fileName;
    if (window.webkitURL != null) downloadLink.href = window.webkitURL.createObjectURL(textFileAsBlob);
    else {
      downloadLink.href = window.URL.createObjectURL(textFileAsBlob);
      downloadLink.onclick = ((mouseEvent) => document.body.removeChild(downloadLink));
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
    }
    downloadLink.click();
  }

  getDisplayedColumns() {
    if (this.isCompliance) {
      this.clientOrderDetailsTableColumns = this.clientOrderDetailsTableColumns.filter(item =>
        item !== "expirationDate" && item !== "remainingDays" && item !== "delivrables" && item !== "status" && item !== "actions"
      );
    }

    if (this.isFinance) {
      this.clientOrderDetailsTableColumns = this.clientOrderDetailsTableColumns.filter(item =>
        item !== "expirationDate" && item !== "remainingDays" && item !== "delivrables" && item !== "exchangeFees" && item !== "status" && item !== "actions"
      );
    }


    if (this.isSupportView) {
      this.clientOrderDetailsTableColumns = this.clientOrderDetailsTableColumns.filter(item =>
        item !== "expirationDate" && item !== "remainingDays" && item !== "delivrables" && item !== "exchangeFees"
      );
      this.clientOrderDetailsTableColumns.push('actions');
    }
  }

  openLogsForSupport(row) {
    this.openLogs.emit(row);
  }

  subscribeToOpenRetryDeltaDialogForSupport(retryMode, itemReference) {
    this.openRetryDeltaDialog.emit({ 'retryMode': retryMode, 'itemReference': itemReference });
  }

  openLinksDialog(links, item) {
    this.linksDialog.open(LinksViewComponent, { width: '800px', data: { links: links, userToken: this.token, period: this.period, item: item } });
  }

  openMicsDialog(mics, item) {
    this.micsDialog.open(ListMicsComponent, { 
      width: '550px', 
      data: { 
        mics: mics,
         item: item 
        } 
      });
  }

}

import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, Subscription, throwError } from 'rxjs';
import jwt_decode from "jwt-decode";
import { map, catchError } from "rxjs/operators"

@Injectable({
    providedIn: 'root'
})
export class AuthentificationService {

    private logoutEvent: EventEmitter<any>;

    constructor(private http: HttpClient) {
        this.logoutEvent = new EventEmitter<any>();
    }

    getRoles(): Array<string> {
        const token = sessionStorage.getItem('token');
        if (token) return this.decodeJWTToken(token)["roleName"];
        return [];
    }

    login(email: string, password): Observable<any> {

        return this.http.post<any>(environment.api + '/user/check', { email, pwd: password })
            .pipe(
                map((res) => {

                    sessionStorage.setItem('token', res.token);
                    localStorage.setItem('ula', 'true');
                    sessionStorage.removeItem('register');
                    this.logoutEvent.emit({ source: this, event: "login" });
                    return res;
                }))
    }

    logout(): Observable<any> {

        return this.http.get<any>(environment.api + '/user/logout').pipe(map((res) => {
            sessionStorage.removeItem('token');
            sessionStorage.setItem('dataset', JSON.stringify({ "dataset": "", "title": "" }));
            this.logoutEvent.emit({ source: this, event: "logout" });
            return res;
        })).pipe(catchError((error) => {

            this.errorHandler(error)
            return throwError(error)
        }));
    }

    errorHandler(error: any): void {
        sessionStorage.removeItem('token');
        sessionStorage.setItem('dataset', JSON.stringify({ "dataset": "", "title": "" }));
        this.logoutEvent.emit({ source: this, event: "logout" });
    }

    islogin(url: any): Observable<any> {
        return this.http.post<any>(environment.api + '/user/islogin', url);
    }

    subscribe(generatorOrNext?: any, error?: any, complete?: any): Subscription {
        return this.logoutEvent.subscribe(generatorOrNext, error, complete);
    }

    decodeJWTToken(token: string) {
        return jwt_decode(token);
    }

}

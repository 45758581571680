import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { OrderService } from '../../../services/order.service';
import { CurrencyService } from '../../../services/currency.service';
import { DataTableDirective } from 'angular-datatables';
import { BreadCrumbService } from '../../../services/bread-crumb.service';
import { DatePipe } from '@angular/common';
import { ProductService } from '../../../services/product.service';

class DataTablesResponse {

    listorders: any[];
    draw: number;
    recordsFiltered: number;
    recordsTotal: number;
}

class Orders {
    _id: string;
    id: string;
    name: string;
}

@Component({
    selector: 'app-ordersp',
    templateUrl: './ordersp.component.html',
    styleUrls: ['./ordersp.component.css'],
    providers: [DatePipe]
})
export class OrderspComponent implements OnInit {
    @ViewChild("SDate") submitdateinput: ElementRef;
    @ViewChild("PType") purchasetypeinput: any;
    state: string;
    states: Array<any>;
    search: string;
    message: string;
    dtTrigger: Subject<any> = new Subject();
    dtOptions: DataTables.Settings = {};
    listorders: Orders[] = [];
    orders: Array<object>;
    symbols: any[];

    constructor(
        private orderService: OrderService,
        private currencyService: CurrencyService,
        private breadCrumbService: BreadCrumbService,
        private productService: ProductService) { }

    @ViewChild(DataTableDirective, { static: false })
    datatableElement: DataTableDirective;
    private countriesForm: NgForm;

    ngOnInit() {
        this.state = 'PVP';
        this.getListStates();
        this.getCurrencies();
        this.getOrders();
    }

    getHt(val, currency, currencyTxUsd, currencyTx, discount) {
        if (currency !== 'usd') {
            let v = ((val / currencyTxUsd) * currencyTx);
            return v - (v * discount / 100);
        } else {
            return val - (val * discount / 100);
        }
    }

    precisionRound(number, precision) {
        var factor = Math.pow(10, precision);
        return Math.round(number * factor) / factor;
    }

    getListStates() {
        this.orderService.getListStates({}).subscribe(order => { this.states = order['states'] });
    }

    getStateName(stateId) {
        if (!this.states) return stateId;
        return this.states.filter(state => state.id === stateId)[0] ? this.states.filter(state => state.id === stateId)[0].name : stateId;
    }

    getCurrencies() {
        this.currencyService.getCurrencies().subscribe(listOfCurrencies => {
            this.symbols = [];
            listOfCurrencies.currencies.forEach(currency => { this.symbols[currency.id] = currency.symbol });
        });
    }

    getOrders() {
        this.productService.getOrders().subscribe(res => {
            this.orders = res.orders;
        })
    }

}

import { Injectable, Component, OnInit } from '@angular/core';
import { OrderService } from '../../../services/order.service';
import { CurrencyService } from '../../../services/currency.service';
import { DatePipe } from '@angular/common';
import { FinanceService } from '../../../services/finance.service';
import { MatDialog } from '@angular/material/dialog';
import { ExportOrdersComponent } from '../export-orders/export-orders.component';

@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.css'],
    providers: [DatePipe]
})

@Injectable()
export class OrdersComponent implements OnInit {
    export: boolean;
    state: string;
    states: Array<any>;
    orders: Array<object>;
    symbols: any[];

    constructor(
        private financeService: FinanceService,
        private orderService: OrderService,
        private currencyService: CurrencyService,
        public dialog: MatDialog
    ) { }

    ngOnInit() {
        this.export = false;
        this.state = 'PVF';
        this.getOrders();
        this.getListStates();
        this.getCurrencies();
    }


    getOrders() {
        this.financeService.getOrders().subscribe(result => {
            this.orders = result.orders;
        });
    }

    openExport() {
        this.export = true;
        const dialogRef = this.dialog.open(ExportOrdersComponent);

        dialogRef.afterClosed().subscribe(result => {
            this.export = false;
        });
    }

    getListStates() {
        this.orderService.getListStates({}).subscribe(res => {
            this.states = res['states'];
        });
    }
    getCurrencies() {
        this.currencyService.getCurrencies().subscribe(r => {
            this.symbols = [];
            r.currencies.forEach(s => {
                this.symbols[s.id] = s.symbol;
            });
        });
    }

}

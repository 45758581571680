import { Routes } from "@angular/router";
import { GuardGuard } from "../../guard/guard.guard";
import { ConfigcComponent } from "./config/configc.component";
import { OrderscComponent } from "./orders/ordersc.component";
import { OrderscViewComponent } from "./orders-view/ordersc-view.component";
import { UsersComponent } from "../administration/users/users.component";
import { ComplianceComponent } from "./compliance/compliance.component";

export const complianceRoutes: Routes = [
  {
    path: "compliance",
    component: ComplianceComponent,
    children: [
      { path: 'commandes', component: UsersComponent, canActivate: [GuardGuard] },
      { path: 'config', component: ConfigcComponent, canActivate: [GuardGuard] },
      { path: 'orders', component: OrderscComponent, canActivate: [GuardGuard] },
      { path: 'orderview/:id', component: OrderscViewComponent, canActivate: [GuardGuard] }
    ]
  }
]

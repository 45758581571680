import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SwalAlertService } from '../../../shared/swal-alert/swal-alert.service';
import { FinanceService } from '../../../services/finance.service';


@Component({
  selector: 'app-refresh-pir',
  templateUrl: './refresh-pir.component.html',
  styleUrls: ['./refresh-pir.component.css']
})
export class RefreshPIRComponent implements OnInit {

  RefreshForm: FormGroup = new FormGroup({});
  message: String = `Export all data`;
  FirstIdInput: number;
  LastIdInput: number;


  constructor(private formBuilder: FormBuilder, private financeService: FinanceService, private swalService: SwalAlertService,) { }

  ngOnInit(): void {
    this.RefreshForm = this.formBuilder.group({
      'FirstId': [],
      'LastId': [],
    });
  }

  RefreshPIR() {
    var isfirstIdLower = this.compareIds();
    if (this.RefreshForm.valid && isfirstIdLower) {
      this.financeService.RefreshPIR(this.RefreshForm.get('FirstId').value, this.RefreshForm.get('LastId').value).subscribe(result => {

      });
      this.getSwalToastNotification();
    }
    else {
      if (!isfirstIdLower) {
        alert("First ID cant be bigger than last ID");
      }
    }
  }

  compareIds() {
    const FirstId = this.RefreshForm.get('FirstId').value;
    const LastId = this.RefreshForm.get('LastId').value;
    if ((FirstId != null && LastId != null) && (FirstId > LastId)) {
      return false;
    }
    return true;
  }

  setMessage(): void {
    if (this.RefreshForm.valid && this.compareIds()) {
      this.message = "Invalid Inputs";
      return;
    }
    if (this.FirstIdInput && this.LastIdInput) {
      this.message = `Export from ID ${this.FirstIdInput} to ID ${this.LastIdInput}`;
    }
    else if (!this.FirstIdInput && !this.LastIdInput) {
      this.message = `Export all data`;
    } else {
      var firstmessage = this.FirstIdInput ? `ID ${this.FirstIdInput}` : "first element";
      var lastmessage = this.LastIdInput ? `ID ${this.LastIdInput} ` : "last element";
      this.message = `Export from ${firstmessage} to ${lastmessage} `;
    }
  }

  getSwalToastNotification() {
    this.swalService.getSwalToastNotification(false, 2500, true, 'Refresh PIR done with success');
  }

}

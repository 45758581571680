import { Routes } from "@angular/router";
import { GuardGuard } from "../../guard/guard.guard";
import { OrderspViewComponent } from "./orders-view/ordersp-view.component";
import { OrderspComponent } from "./orders/ordersp.component";
import { ConfigpComponent } from "./config/configp.component";
import { ProductComponent } from "./product/product.component";
import { ExpiringOrdersComponent } from "./expiring-orders/expiring-orders.component";
import { OrderRenewalComponent } from "./order-renewal/order-renewal.component";

export const productRoutes: Routes = [
    {
        path: 'product', component: ProductComponent,
        children: [
            { path: 'config', component: ConfigpComponent, canActivate: [GuardGuard] },
            { path: 'orders', component: OrderspComponent, canActivate: [GuardGuard] },
            { path: 'orders/renewal', component: ExpiringOrdersComponent, canActivate: [GuardGuard] },
            { path: 'orders/renewal/:id', component: OrderRenewalComponent, canActivate: [GuardGuard] },
            { path: 'orderview/:id', component: OrderspViewComponent, canActivate: [GuardGuard] }
        ]
    }
]
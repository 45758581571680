export const environment = {
    production: false,
    api: 'http://localhost:3000/api/v1',
    gateway: "https://emea-gateway.hod-lab.iress.com",
    elastic: {
        instrument: {
            derivatives: 'derivatives',
            nonderivatives: 'nonderivatives',
            index: ['derivatives', 'nonderivatives'],
            type: ['productDB-derivatives', 'productDB-nonderivatives']
        },
        feed: {
            productdb: 'productdb',
            index: ['productdb'],
            type: ['productDB']
        }
    },
    captchaKey: '6Lc-9D4lAAAAALDBp3yCpWQT4-3VZZhqE14dPPWY'
};

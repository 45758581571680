import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mail-failed',
  templateUrl: './mail-failed.component.html',
  styleUrls: ['./mail-failed.component.css']
})
export class MailFailedComponent implements OnInit {

  constructor() { }
  ngOnInit() { }

}

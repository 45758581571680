import { Injectable } from '@angular/core';
import { ClientInformation } from '../../modules/client/models/client-information.model';

@Injectable({
  providedIn: 'root'
})
export class ClientInformationsBuilderService {

  constructor() { }

  transformCmdToClientOrderInformations(cmd) {
    var clientInfo = <ClientInformation>{}

    clientInfo.company = cmd.companyName;
    clientInfo.firstName = cmd.firstname;
    clientInfo.lastName = cmd.lastname;
    clientInfo.job = cmd.job;
    clientInfo.countryBilling = cmd.countryBilling;

    return clientInfo;
  }
}

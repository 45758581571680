import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthentificationService } from '../services/authentification.service';

@Injectable({
    providedIn: 'root'
})
export class GuardGuard implements CanActivate {


    constructor(
        private router: Router,
        private authService: AuthentificationService) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {


        const token = sessionStorage.getItem('token');
        if (token) {
            return new Promise((resolve) => {
                this.authService.islogin({ page: state.url }).subscribe(res => {
                    if (res.role > 0) {
                        resolve(true);
                    } else {

                        this.router.navigate(['/login'], { queryParams: { 'redirectURL': state.url } });
                        resolve(false);
                    }
                },
                    _error => this.authService.logout().subscribe(() => resolve(false)));
            });
        } else {
            this.router.navigate(['/login'], { queryParams: { 'redirectURL': state.url } });
            return Promise.resolve(false);
        }
    }
}

import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationError } from 'ajv';
import { FinanceService } from '../../../services/finance.service';

@Component({
  selector: 'app-pirexport',
  templateUrl: './pirexport.component.html',
  styleUrls: ['./pirexport.component.css']
})
export class PIRExportComponent implements OnInit {

  constructor(private financeSerivce: FinanceService, private formBuilder: FormBuilder) { }
  FirstIdInput: number
  LastIdInput: number
  message: String = `Export all data`;
  ExportForm: FormGroup = new FormGroup({});
  ngOnInit(): void {
    this.ExportForm = this.formBuilder.group({
      'FirstId': [, { validators: [Validators.min(1)] }],
      'LastId': [, { validators: [Validators.min(1)] }],

    })
  }
  compareIds() {
    const FirstId = this.ExportForm.get('FirstId').value;
    const LastId = this.ExportForm.get('LastId').value;
    if ((FirstId != null && LastId != null) && (FirstId > LastId)) {
      // this.ExportForm.get('LastId').setErrors({ "incorrect": false })
      // this.ExportForm.get('FirstId').setErrors({ "incorrect": false })
      return false
    }
    return true

  };
  setMessage(): void {
    if (this.ExportForm.valid && this.compareIds()) {
      this.message = "Invalid Inputs"
      return;
    }
    if (this.FirstIdInput && this.LastIdInput) {
      this.message = `Export from ID ${this.FirstIdInput} to ID ${this.LastIdInput}`
    }
    else if (!this.FirstIdInput && !this.LastIdInput) {
      this.message = `Export all data`
    } else {
      var firstmessage = this.FirstIdInput ? `ID ${this.FirstIdInput}` : "first element"
      var lastmessage = this.LastIdInput ? `ID ${this.LastIdInput} ` : "last element"
      this.message = `Export from ${firstmessage} to ${lastmessage} `
    }
  }
  exportPIR() {
    var isfirstIdLower = this.compareIds()
    if (this.ExportForm.valid && isfirstIdLower) {
      this.financeSerivce.exportPIR(this.ExportForm.get('FirstId').value, this.ExportForm.get('LastId').value).subscribe(result => {
        var blob = new Blob([result.body], { type: "application/csv" });
        var link = document.createElement('a');
        link.download = result.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1];
        link.href = window.URL.createObjectURL(blob);
        link.click();
      })
    }
    else {
      if (!isfirstIdLower) {
        alert("First ID cant be bigger than last ID");
      }
    }

  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwalAlertService } from '../../../shared/swal-alert/swal-alert.service';
import { SupportService } from '../../../services/support.service';

@Component({
  selector: 'app-retry-delta-modal',
  templateUrl: './retry-delta-modal.component.html',
  styleUrls: ['./retry-delta-modal.component.css']
})
export class RetryDeltaModalComponent implements OnInit {

  retryMode: string;
  itemReference: string;
  constructor(public dialogRef: MatDialogRef<RetryDeltaModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private supportService: SupportService,
    private swalService: SwalAlertService) { }

  ngOnInit(): void {
    this.retryMode = this.data.retryMode;
    this.itemReference = this.data.itemReference;
  }

  retry() {
    this.supportService.getRetry(this.itemReference, this.retryMode).subscribe(result => {
      if (result) {
        this.dialogRef.close(true);
        let title = `${this.retryMode} Retry for ${this.itemReference}`;
        let text = `Your ${this.retryMode} Retry for <b> ${this.itemReference} </b> has been executed successfully !`;
        this.swalService.getSwalForConfirm(title, text, "success", false);
      }
    },
      error => {
        this.dialogRef.close(false);
        let title = `${this.retryMode} Retry for ${this.itemReference}`;
        let text = `Your ${this.retryMode} Retry for <b> ${this.itemReference} </b> has been failed !, ${error.error.error}`;
        this.swalService.getSwalForConfirm(title, text, "error", false);
      });
  }

}

import { Injectable } from '@angular/core';
import { OrderInformation } from '../../modules/order/models/order-information.model';

@Injectable({
  providedIn: 'root'
})
export class OrderInformationsBuilderService {

  constructor() { }

  transformCmdToOrderInformations(cmd) {
    var orderInfo = <OrderInformation>{}

    orderInfo.id = cmd.id;
    orderInfo._id = cmd._id;
    orderInfo.submissionDate = cmd.submissionDate;
    orderInfo.payment = cmd.payment;
    orderInfo.invoice = cmd.idCommande;
    orderInfo.proForma = cmd.idProForma;
    orderInfo.state = cmd.state;
    orderInfo.sales = cmd.sales;
    orderInfo.type = cmd.type;

    return orderInfo;
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SupportService } from '../../../services/support.service';
import { BreadCrumbService } from '../../../services/bread-crumb.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {

  splitedroute: string[];
  id: string;
  order: any;

  constructor(
    private router: Router,
    private breadCrumbService: BreadCrumbService,
    private supportService: SupportService
  ) { }

  ngOnInit(): void {
    this.getCurrentComponent();
    this.breadCrumbService.orderForBreadCrumb.subscribe(order => {
      if (!order) {
        this.id = "";
        return;
      }
      this.id = order.id;
      this.order = order
      this.router.events.subscribe(route => {
        if (route instanceof NavigationEnd) {
          this.getCurrentComponent()
        }
      });
    });
  }
  getCurrentComponent() {
    this.splitedroute = this.router.url.split('/');
  }

  generateInvoiceToSupport(response) {
    if (response) {
      this.supportService.linksToGenerate();
    }
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StringToDateTimeConverterService {

  constructor() { }

  convertStringToDateTime(date){
    try {
      let arrayDate = date.split("-");
      return new Date(arrayDate[0],arrayDate[1] - 1,arrayDate[2]);
    } catch (error) {
    }
  }
}

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ProductService } from '../../../services/product.service';
import { BreadCrumbService } from '../../../services/bread-crumb.service';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-list-expiring-orders',
    templateUrl: './list-expiring-orders.component.html',
    styleUrls: ['./list-expiring-orders.component.css']
})
export class ListExpiringOrdersComponent implements OnInit {

    @ViewChild(MatPaginator) set paginator(value: MatPaginator) { this.dataSource.paginator = value; }
    @ViewChild(MatSort) set sort(value: MatSort) { this.dataSource.sort = value; }

    @Input() orders: Array<object>;

    dataSource: MatTableDataSource<any>;
    displayedColumns: string[] = ['client', 'orderId', 'anniversaryDate', 'daysAfterAnniversary', 'renew'];
    selctedOrder: any;

    //filters
    filteredValues: any = { id: '', companyName: '' }
    orderIdFilter = new FormControl('');
    clientFilter = new FormControl('');


    constructor(
        private productService: ProductService,
        private breadCrumbService: BreadCrumbService,) { }
        
    ngOnInit(): void {

        this.listOrders();
        this.dataFilterControl();

    }

    listOrders() {
        this.dataSource = new MatTableDataSource(this.orders);
        this.dataSource.filterPredicate = this.filterPredicate();
        this.dataSource.filter = JSON.stringify(this.filteredValues);
    }

    getOrder(orderId: string) {

        this.productService.selectedExpiredOrder = this.productService.expiringOrders.find(order => order.longId === orderId);
        this.breadCrumbService.getOrderForView(this.productService.selectedExpiredOrder);
    }

    private dataFilterControl() {

        this.orderIdFilter.valueChanges.subscribe((orderIdFilterValue) => {
            this.filteredValues['id'] = orderIdFilterValue;
            this.dataSource.filter = JSON.stringify(this.filteredValues);
        });

        this.clientFilter.valueChanges.subscribe((companyNameFilterValue) => {
            this.filteredValues['companyName'] = companyNameFilterValue;
            this.dataSource.filter = JSON.stringify(this.filteredValues);
        });
    }

    filterPredicate(): (data: any, filter: string) => boolean {
        let filterFunction = function (data, filter): boolean {
            let searchTerms = JSON.parse(filter);
            return data.id.toString().toLowerCase().indexOf(searchTerms.id) !== -1 &&
                data.companyName.toString().trim().toLowerCase().indexOf(searchTerms.companyName.toLowerCase()) !== -1
        }
        return filterFunction;
    }
}
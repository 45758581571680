import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, Routes } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgxLoaderService } from '../../../services/ngx-loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  constructor(private ngxLoaderService: NgxLoaderService, private ngxLoader: NgxUiLoaderService,
    route: Router) { }

  ngOnInit(): void {
    this.ngxLoaderService.config.bgsColor = "#3A1C46";
    this.ngxLoaderService.config.fgsColor = "#3A1C46";
    this.ngxLoaderService.config.pbColor = "#0E1F3A";
    this.ngxLoaderService.config.overlayColor = "rgba(40,40,40,0.82)";
    this.ngxLoaderService.config.logoUrl = "../assets/img/qh-logo-only.png";
    this.ngxLoaderService.config.logoPosition = "bottom-left";
    this.ngxLoaderService.config.logoSize = 60;
    this.ngxLoaderService.config.fgsType = "three-strings";
    this.ngxLoaderService.config.fgsSize = 100;
    this.ngxLoaderService.config.bgsSize = 70;
    this.ngxLoaderService.config.bgsType = "ball-scale-multiple";
    this.ngxLoaderService.config.bgsOpacity = 0.8
    this.ngxLoaderService.config.masterLoaderId = "loader-01";
    this.ngxLoader.start('loader-01')
    this.ngxLoader.stop('loader-01')
    // this.ngxLoader.startBackgroundLoader('loader-01');
    // this.ngxLoader.stopBackgroundLoader('loader-01');
  }
}

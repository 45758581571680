import { Component, OnInit } from '@angular/core';
import { OrderService } from '../../../services/order.service';
import { BreadCrumbService } from '../../../services/bread-crumb.service';
import { SwalAlertService } from '../../../shared/swal-alert/swal-alert.service';
import { OrderscViewComponent } from '../orders-view/ordersc-view.component';
import { ComplianceService } from '../../../services/compliance.service';

@Component({
  selector: 'app-compliance',
  templateUrl: './compliance.component.html',
  styleUrls: ['./compliance.component.css']
})
export class ComplianceComponent implements OnInit {

  currentComponent: string
  splitedroute: string[];
  id: any;
  menuActions = new Array<object>();
  state: any;
  order: any;

  orderscViewComponent: OrderscViewComponent;
  constructor(
    private breadCrumbService: BreadCrumbService,
    private complianceService:ComplianceService
  ) { }

  ngOnInit(): void {
    this.breadCrumbService.orderForBreadCrumb.subscribe(order => {
      if (!order) {
        this.id = "";
        return;
      }
      this.id = order.id;
      this.state = order.state
      this.order = order
    });
  }


  validateOrderToCompliance(response) {
    if (response)
    this.complianceService.validateOrderForCompliance();
  }
}
